import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { GrFormPrevious } from "react-icons/gr";
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS

function AdminData() {
    const [centers, setCenters] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [selectedCenterState, setSelectedCenterState] = useState('');
    const [selectedDoctorState, setSelectedDoctorState] = useState('');
    const [selectedCenter, setSelectedCenter] = useState(null);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [removeCenterState, setRemoveCenterState] = useState('');
    const [removeDoctorState, setRemoveDoctorState] = useState('');

    const [doctorDetails, setDoctorDetails] = useState({
        doctorId: '',
        name: '',
        specialization: '',
        expressAppointmentEnabled: false,
    });

    const [centerDetails, setCenterDetails] = useState({
        centerId: '',
        centerName: '',
    });

    const navigate = useNavigate();
    const baseURL = 'https://bezrucova.ccw.sk/bezrucovabackend';
    const authToken = localStorage.getItem('authToken');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
    };

    const fetchCenters = () => {
        if (!authToken) {
            console.error('No auth token found. Please login.');
            alert('Chyba: Prosím prihláste sa');
            return;
        }
        fetch(`${baseURL}/centers/getByPermission`, {
            method: 'GET',
            headers
        })
            .then(response => response.json())
            .then(data => {
                setCenters(data);
            })
            .catch(error => console.error('Error fetching centers:', error));
    };

    const fetchDoctors = () => {
        if (!authToken) {
            console.error('No auth token found. Please login.');
            alert('Chyba: Prosím prihláste sa');
            return;
        }
        fetch(`${baseURL}/doctors/getByPermission`, {
            method: 'GET',
            headers
        })
            .then(response => response.json())
            .then(data => {
                setDoctors(data);
            })
            .catch(error => console.error('Error fetching doctors:', error));
    };

    useEffect(() => {
        fetchCenters();
        fetchDoctors();
    }, []);

    useEffect(() => {
        const selectedDoc = doctors.find(doc => doc.doctorId === selectedDoctorState);
        setSelectedDoctor(selectedDoc);
    }, [selectedDoctorState, doctors]);

    useEffect(() => {
        const selectedCent = centers.find(cent => cent.centerId === selectedCenterState);
        setSelectedCenter(selectedCent);
    }, [selectedCenterState, centers]);

    useEffect(() => {
        if (selectedDoctor) {
            setDoctorDetails({
                doctorId: selectedDoctor.doctorId || '',
                name: selectedDoctor.name || '',
                specialization: selectedDoctor.specialization || '',
                expressAppointmentEnabled: selectedDoctor.expressAppointmentEnabled || false,
            });
        } else {
            setDoctorDetails({
                doctorId: '',
                name: '',
                specialization: '',
                expressAppointmentEnabled: false,
            });
        }
        if (selectedCenter) {
            setCenterDetails({
                centerId: selectedCenter.centerId || '',
                centerName: selectedCenter.name || '',
            });
        } else {
            setCenterDetails({
                centerId: '',
                centerName: '',
            });
        }
    }, [selectedDoctor, selectedCenter]);

    const handleDoctorInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setDoctorDetails((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleCenterInputChange = (e) => {
        const { name, value } = e.target;
        setCenterDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const confirmCenterUpdate = async () => {
        if (!selectedCenter || !selectedCenter.centerId) {
            toast.error('Prosím, vyberte centrum pre úpravu.');
            return;
        }

        if (!centerDetails.centerName.trim()) {
            toast.error('Názov centra nemôže byť prázdny.');
            return;
        }

        const requestBody = {
            alias: selectedCenter.centerId,
            meno: centerDetails.centerName
        };

        if (!authToken) {
            console.error('No auth token found. Please login.');
            alert('Chyba: Prosím prihláste sa');
            return;
        }

        try {
            const response = await fetch(`${baseURL}/centers`, {
                method: 'PUT',
                headers,
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            await response.json();
            fetchCenters();
            toast.success('Centrum bolo úspešne upravené.');
        } catch (error) {
            console.error('Error updating center:', error);
            toast.error('Chyba pri aktualizácii centra.');
        }
    };

    const confirmDoctorUpdate = async () => {
        if (!selectedDoctor || !selectedDoctor.doctorId) {
            toast.error('Prosím, vyberte lekára pre úpravu.');
            return;
        }
    
        if (!doctorDetails.name.trim()) {
            toast.error('Meno lekára nemôže byť prázdne.');
            return;
        }
    
        if (!doctorDetails.specialization.trim()) {
            toast.error('Špecializácia lekára nemôže byť prázdna.');
            return;
        }
    
        const requestBody = {
            name: doctorDetails.name,
            specialization: doctorDetails.specialization,
            expressAppointmentEnabled: doctorDetails.expressAppointmentEnabled,
        };
    
        if (!authToken) {
            console.error('No auth token found. Please login.');
            alert('Chyba: Prosím prihláste sa');
            return;
        }
    
        try {
            const response = await fetch(`${baseURL}/doctors/${selectedDoctor.doctorId}`, {
                method: 'PUT',
                headers,
                body: JSON.stringify(requestBody),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            await response.json();
            fetchDoctors();
            toast.success('Lekár bol úspešne upravený.');
    
            if (!doctorDetails.expressAppointmentEnabled) {
                const pricesResponse = await fetch(
                    `${baseURL}/prices/findByDoctor/${selectedDoctor.doctorId}`,
                    {
                        method: 'GET',
                        headers,
                    }
                );
    
                if (!pricesResponse.ok) {
                    throw new Error('Failed to fetch existing prices');
                }
    
                const existingPrices = await pricesResponse.json();
    
                const pricesData = {
                    vv: [
                        existingPrices.vv[0],
                        0,
                        0
                    ],
                    basic: [
                        existingPrices.basic[0],
                        0,
                        0
                    ],
                    plus: [
                        existingPrices.plus[0],
                        0,
                        0
                    ],
                    max: [
                        existingPrices.max[0],
                        0,
                        0
                    ],
                };
    
                const updatePricesResponse = await fetch(`${baseURL}/prices/${selectedDoctor.doctorId}`, {
                    method: 'PUT',
                    headers,
                    body: JSON.stringify(pricesData),
                });
    
                if (!updatePricesResponse.ok) {
                    throw new Error('Failed to update prices');
                }
    
            }
        } catch (error) {
            console.error('Error updating doctor or prices:', error);
            toast.error('Chyba pri aktualizácii lekára alebo cien.');
        }
    };
    

    const Back = () => {
        navigate('/login');
    };

    const deleteCenter = () => {
        if (!removeCenterState) {
            toast.error('Prosím, vyberte centrum na odstránenie.');
            return;
        }

        const confirmDelete = window.confirm('Ste si istí, že chcete odstrániť toto centrum?');
        if (confirmDelete) {
            if (!authToken) {
                console.error('No auth token found. Please login.');
                alert('Chyba: Prosím prihláste sa');
                return;
            }
            fetch(`${baseURL}/centers/${removeCenterState}`, {
                method: 'DELETE',
                headers,
            })
            .then(response => {
                if (response.ok) {
                    fetchCenters();
                    fetchDoctors();
                    setRemoveCenterState('');
                    setSelectedDoctorState('');
                    setRemoveDoctorState('');
                    setSelectedDoctor(null);
                    toast.success('Centrum bolo úspešne odstránené.');
                } else {
                    throw new Error('Error removing center');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Chyba pri odstraňovaní centra.');
            });
        }
    };

    const deleteDoctor = () => {
        if (!removeDoctorState) {
            toast.error('Prosím, vyberte lekára na odstránenie.');
            return;
        }

        const confirmDelete = window.confirm('Ste si istí, že chcete odstrániť tohto lekára?');
        if (confirmDelete) {
            fetch(`${baseURL}/doctors/${removeDoctorState}`, {
                method: 'DELETE',
                headers,
            })
            .then(response => {
                if (response.ok) {
                    fetchDoctors();
                    setRemoveDoctorState('');
                    toast.success('Lekár bol úspešne odstránený.');
                } else {
                    throw new Error('Error removing doctor');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Chyba pri odstraňovaní lekára.');
            });
        }
    };
    return (
        <div className="bg-cover bg-center flex justify-center" style={{ backgroundImage: "url('/pictures/background.jpg')" }}>
            <div className="p-4 sm:p-8 bg-slate-100 relative w-full flex flex-col">
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    data-testid="toast-container"  for ToastContainer
                />
                <div className="w-full flex justify-center bg-opacity-80 bg-slate-100 p-2">
                </div>
                <div className="flex flex-col sm:flex-row sm:gap-4 justify-between items-start rounded-lg p-4 sm:p-8">
                    {/* Left - Update Doctor */}
                    <div className="flex flex-col text-black w-full sm:w-1/3">
                        <h2 className="text-lg font-bold mb-4">Uprav údaje pre lekára</h2>
                        <div className="mb-4">
                            <label className="block mb-1 font-semibold" htmlFor="doctor-select">Lekár:</label>
                            <select
                                id="doctor-select"
                                data-testid="doctor-select" 
                                value={selectedDoctorState}
                                onChange={(e) => setSelectedDoctorState(e.target.value)}
                                className="w-full border border-gray-400 p-2 rounded"
                            >
                                <option value="">-- Vyberte lekára --</option>
                                {doctors.map((doctor) => (
                                    <option key={doctor.doctorId} value={doctor.doctorId}>
                                        {doctor.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1 font-semibold" htmlFor="doctor-name-input">Meno:</label>
                            <input
                                type="text"
                                id="doctor-name-input"
                                data-testid="doctor-name-input" 
                                name="name"
                                value={doctorDetails.name}
                                onChange={handleDoctorInputChange}
                                className="w-full border border-gray-400 p-2 rounded"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1 font-semibold" htmlFor="doctor-specialization-input">Špecializácia:</label>
                            <input
                                type="text"
                                id="doctor-specialization-input"
                                data-testid="doctor-specialization-input" 
                                name="specialization"
                                value={doctorDetails.specialization}
                                onChange={handleDoctorInputChange}
                                className="w-full border border-gray-400 p-2 rounded"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="flex items-center text-xl" htmlFor="doctor-express-appointment-checkbox">
                                <input
                                    type="checkbox"
                                    id="doctor-express-appointment-checkbox"
                                    data-testid="doctor-express-appointment-checkbox" 
                                    name="expressAppointmentEnabled"
                                    checked={doctorDetails.expressAppointmentEnabled}
                                    onChange={handleDoctorInputChange}
                                    className="mr-2 w-4 h-4"
                                />
                                Expresné ceny
                            </label>
                        </div>

                        <button
                            data-testid="save-doctor-button" 
                            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                            onClick={confirmDoctorUpdate}
                        >
                            Ulož zmeny doktora
                        </button>
                    </div>

                    {/* Divider */}
                    <div className="w-px bg-black mx-1 self-stretch"></div>

                    {/* Middle - Update Center */}
                    <div className="flex flex-col text-black w-full sm:w-1/3">
                        <h2 className="text-lg font-bold mb-4">Uprav údaje pre centrum</h2>
                        <div className="mb-4">
                            <label className="block mb-1 font-semibold" htmlFor="center-select">Centrum:</label>
                            <select
                                id="center-select"
                                data-testid="center-select" 
                                value={selectedCenterState}
                                onChange={(e) => setSelectedCenterState(e.target.value)}
                                className="w-full border border-gray-400 p-2 rounded"
                            >
                                <option value="">-- Vyberte centrum --</option>
                                {centers.map((center) => (
                                    <option key={center.centerId} value={center.centerId}>
                                        {center.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1 font-semibold" htmlFor="center-name-input">Názov centra:</label>
                            <input
                                type="text"
                                id="center-name-input"
                                data-testid="center-name-input" 
                                name="centerName"
                                value={centerDetails.centerName}
                                onChange={handleCenterInputChange}
                                className="w-full border border-gray-400 p-2 rounded"
                            />
                        </div>

                        <button
                            data-testid="save-center-button" 
                            onClick={confirmCenterUpdate}
                            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                        >
                            Ulož zmeny centra
                        </button>

                    </div>

                    {/* Divider */}
                    <div className="w-px bg-black mx-1 self-stretch"></div>

                    {/* Right - Delete */}
                    <div className="flex flex-col text-black w-full sm:w-1/3">
                        <h2 className="text-lg font-bold mb-4">Odstráň centrá a lekárov</h2>
                        <div className="mb-4">
                            <label className="block mb-1 font-semibold" htmlFor="delete-center-select">Centrum:</label>
                            <select
                                id="delete-center-select"
                                data-testid="delete-center-select" 
                                value={removeCenterState}
                                onChange={(e) => setRemoveCenterState(e.target.value)}
                                className="w-full border border-gray-400 p-2 rounded">
                                <option key="default-center" value="">
                                    -- Vyberte centrum --
                                </option>
                                {centers.map((center) => (
                                    <option key={center.centerId} value={center.centerId}>
                                        {center.name}
                                    </option>
                                ))}
                            </select>
                            <button
                                data-testid="delete-center-button" 
                                className="mt-2 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                                onClick={deleteCenter}>
                                Odstráň centrum
                            </button>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1 font-semibold" htmlFor="delete-doctor-select">Lekári:</label>
                            <select
                                id="delete-doctor-select"
                                data-testid="delete-doctor-select" 
                                value={removeDoctorState}
                                onChange={(e) => setRemoveDoctorState(e.target.value)}
                                className="w-full border border-gray-400 p-2 rounded"
                            >
                                <option key="default-doctor" value="">
                                    -- Vyberte lekára --
                                </option>
                                {doctors.map((doctor) => (
                                    <option key={doctor.doctorId} value={doctor.doctorId}>
                                        {doctor.name}
                                    </option>
                                ))}
                            </select>
                            <button
                                data-testid="delete-doctor-button" 
                                className="mt-2 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                                onClick={deleteDoctor}
                            >
                                Odstráň lekára
                            </button>

                        </div>
                    </div>
                </div>
                <div className="w-full flex justify-center bg-opacity-80 bg-slate-100 p-10">
                </div>
                {/* Bottom - Button */}
                <div className="relative mt-4 sm:absolute sm:bottom-4 sm:left-4 flex justify-center sm:justify-start">
                    <button
                        data-testid="back-button" 
                        className="bg-blue-600 mr-5 text-white p-4 rounded-full"
                        onClick={Back}
                    >
                        <GrFormPrevious className="w-6 h-6" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AdminData;
