import React from 'react';
import DoctorsHospitalsAdmin from '../DoctorsHospitalsAdmin';
import AdminGenerate from './AdminGenerate';
import { useNavigate, useLocation } from 'react-router-dom';

const AdminGenerateInfo = ({
    selectedCalendarId,
    onDoctorSelect,
    selectedDoctor,
    onAmbulanceSelect,
    setSelectedDoctorAdmin,
    selectedDoctorAdmin,
    selectedAmbulanceAdmin,
    setSelectedAmbulanceAdmin
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const userRole = localStorage.getItem('userRole');

    const generateSubmit = () => {
        navigate('/adminGenerate');
    };
    const modifyTimeslotSubmit = () => {
        navigate('/adminModify');
    };
    const administrationPoliklinikaSubmit = () => {
        navigate('/adminPrice');
    };
    const administrationDoctorSubmit = () => {
        navigate('/adminDoctor');
    };
    const addDoctorSubmit = () => {
        navigate('/adminAdd');
    };

    return (
        <div className="flex justify-center items-center p-4 sm:p-8 w-full max-w-screen-xl mx-auto">
            <div className="bg-white bg-opacity-60 p-4 sm:p-8 w-full h-auto text-center">
                <h1
                    style={{
                        fontFamily: 'Poppins',
                        fontWeight: 500,
                        fontStyle: 'normal',
                        color: 'rgb(0, 0, 0)',
                    }}
                    className="mb-4 text-3xl sm:text-3xl lg:text-4xl xl:text-4xl"
                >
                    Generovanie nových termínov pre lekárov
                </h1>
                <p
                    style={{
                        fontWeight: 400,
                        fontFamily: 'Poppins',
                        color: 'rgb(0, 0, 0)',
                        fontStyle: 'normal',
                    }}
                    className="mb-6 text-base sm:text-lg lg:text-xl xl:text-1xl"
                >
                    Vyberte si lekára, následne vyberte dni, dĺžku a intervál v ktorom chcete vygenerovať/odstrániť termíny.
                    <br />
                    Ak chcete označiť väčšie obdobie, môžete nakopírovať a prilepiť vybraný týždeň pomocou ikoniek v kalendári.
                    <br />
                </p>
                <div className="flex flex-wrap space-x-4 w-full">
                    <button
                        onClick={modifyTimeslotSubmit}
                        className="flex-1 h-12 bg-slate-200 text-black font-medium rounded border-4 border-black flex items-center justify-center"
                    >
                        Obsadenie termínov
                    </button>
                    <button
                        onClick={generateSubmit}
                        className={`flex-1 h-12 font-medium rounded border-4 border-black flex items-center justify-center ${
                            location.pathname === '/adminGenerate' ? 'bg-green-600 text-white' : 'bg-slate-200 text-black'
                        }`}
                    >
                        Generovanie termínov
                    </button>
                    <button
                        onClick={administrationPoliklinikaSubmit}
                        className="flex-1 h-12 bg-slate-200 text-black font-medium rounded border-4 border-black flex items-center justify-center"
                    >
                        Administrácia cien
                    </button>
                    <button
                        onClick={userRole !== 'admin' ? administrationDoctorSubmit : null}
                        disabled={userRole === 'admin'}
                        className={`flex-1 h-12 font-medium rounded border-4 border-black flex items-center justify-center ${
                            userRole === 'admin' ? 'bg-gray-400 text-gray-700 cursor-not-allowed' : 'bg-slate-200 text-black'
                        }`}
                    >
                        Administrácia lekárov
                    </button>
                    <button
                        onClick={userRole !== 'admin' ? addDoctorSubmit : null}
                        disabled={userRole === 'admin'}
                        className={`flex-1 h-12 font-medium rounded border-4 border-black flex items-center justify-center ${
                            userRole === 'admin' ? 'bg-gray-400 text-gray-700 cursor-not-allowed' : 'bg-slate-200 text-black'
                        }`}
                    >
                        Pridaj lekára/centrum
                    </button>
                </div>
                <div className="w-full mt-4">
                    <DoctorsHospitalsAdmin
                        onDoctorSelect={onDoctorSelect}
                        onAmbulanceSelect={onAmbulanceSelect}
                        setSelectedDoctorAdmin={setSelectedDoctorAdmin}
                        selectedDoctorAdmin={selectedDoctorAdmin}
                        selectedAmbulanceAdmin={selectedAmbulanceAdmin}
                        setSelectedAmbulanceAdmin={setSelectedAmbulanceAdmin}
                    />
                </div>
                <div className="mt-4 w-full">
                    <AdminGenerate
                        selectedDoctor={selectedDoctor}
                        selectedCalendarId={selectedCalendarId}
                    />
                </div>
            </div>
        </div>
    );
};

export default AdminGenerateInfo;
