import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GrFormPrevious } from "react-icons/gr";

const TypeformPreFill = ({ formData, selectedDoctor }) => {
    const navigate = useNavigate();

    const Back = () => {
        navigate('/rezervacia');
    };
/*
    const handleSubmit = async (e) => {
        if (e) e.preventDefault();

        try {
           // console.log("endpoint");
            const response = await fetch('http://130.61.222.41:8080/bezrucovabackend/appointments/reservation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                console.log('Form data sent successfully');
                navigate(`/email`);
            } else {
                console.error('Error sending form data:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
*/
    const generateTypeformUrl = () => {
        const formattedDate = new Date(formData.selectedDate).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    
        const formattedTime = new Date(`1970-01-01T${formData.selectedTime}`).toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
        });
    
        const params = new URLSearchParams({
            'name': `${formData.firstName} ${formData.lastName}`,
            'email': formData.email,
            'number': formData.phone,
            'time': formattedTime,
            'date': formattedDate,
            'adress': formData.address,
            'insurance': formData.insurance,
            'identification_number': formData.identification_number,
            'info': formData.info,
            'card': formData.card,
            'sum': formData.totalPrice,
            'card_price': formData.cardPrice,
            'diff_sum': formData.diffSum,
            'express_price': formData.diffSum,
            'duration': formData.duration,
            'psc': formData.psc,
            'city': formData.city,
            'calendar_id': formData.calendarId
        });
    
        return `${selectedDoctor.typeformUrl}?${params.toString()}`;
    };
    
/*
    useEffect(() => {
        const handleTypeformMessage = (event) => {
            // Allow any Typeform origin dynamically
            if (event.origin.endsWith(".typeform.com") && event.data.type === 'form-submit') {
               // console.log("Event received and matched:", event);
                handleSubmit(event);
            } else {
                console.warn("Event origin or type mismatch:", event.origin, event.data.type);
            }
        };
    
        window.addEventListener('message', handleTypeformMessage);
    
        return () => {
            window.removeEventListener('message', handleTypeformMessage);
        };
    }, []);*/
    

    return (
        <div className="relative w-full h-screen flex justify-center items-center">
            <div className="absolute bg-white bg-opacity-60 p-4 sm:p-8 flex flex-col sm:flex-row justify-center items-center">
                <button
                    className="bg-blue-500 mb-4 sm:mb-0 sm:mr-7 text-white p-2 sm:p-2 rounded-full"
                    onClick={Back}
                >
                    <GrFormPrevious className="w-4 h-4 sm:w-6 sm:h-6" />
                </button>
                <iframe
                    id="typeform-full"
                    className="w-full h-[500px] sm:w-[600px] sm:h-[700px]"
                    src={generateTypeformUrl()}
                    title="Typeform"
                ></iframe>
            </div>
        </div>
    );
};

export default TypeformPreFill;



