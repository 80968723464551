import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GrFormPrevious, GrCircleInformation } from "react-icons/gr";

const ReservationForm = ({ onFormSubmit, formData }) => {
  const [localFormData, setLocalFormData] = useState(formData);
  const [phoneError, setPhoneError] = useState(""); // State for phone validation error
  const [idError, setIdError] = useState(""); // State for birth ID validation error
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));

    if (name === "phone") {
      validatePhone(value);
    } else if (name === "identification_number") {
      validateIdNumber(value);
    }
  };

  const validatePhone = (phone) => {
    const phonePattern = /^\+421\d{8,12}$/; // Regex for +421XXXXXXXXX
    if (!phone) {
        setPhoneError("Telefónne číslo je povinné.");
    } else if (phone.length < 12) {
        setPhoneError("Minimálna dĺžka telefónneho čísla po +421 je 8 číslic.");
    } else if (!phonePattern.test(phone)) {
        setPhoneError(
            "Zadajte správne telefónne číslo vo formáte +421XXXXXXXXX."
        );
    } else {
        setPhoneError(""); // Clear error if valid
    }
};

const validateIdNumber = (idNumber) => {
  const idPattern = /^\d{1,10}$/; // numeric max 10 digits
  if (!idNumber) {
      setIdError("Rodné číslo je povinné.");
  } else if (idNumber.includes('/')) { // Check if /
      setIdError("Zadajte rodné číslo bez /.");
  } else if (idNumber.length < 8) {
      setIdError("Rodné číslo musí obsahovať aspoň 8 číslic.");
  } else if (!idPattern.test(idNumber)) {
      setIdError("Rodné číslo musí obsahovať najviac 10 číslic.");
  } else {
      setIdError("");
  }
};



  const Back = () => {
    navigate('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (phoneError || idError) {
      alert("Prosím, opravte chyby vo formulári.");
      return;
    }

    onFormSubmit(localFormData);

    console.log(localFormData);
    navigate(`/typeform`);
  };


  return (
    <div className="relative w-full h-auto flex justify-center items-center p-4">
      <div className="inset-0 bg-white bg-opacity-60 p-8 flex justify-center items-center w-full max-w-lg mx-auto">
        <div className="w-full bg-white rounded-lg p-4">
          <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-center">Registračný formulár</h2>
          <p className="text-center mb-4 text-sm sm:text-base">Prosíme o vyplnenie registračného formulára</p>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-3">
              <div className="w-full sm:w-1/2">
                <label className="block text-gray-700 text-sm mb-1">
                  Meno <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={localFormData.firstName}
                  onChange={handleChange}
                  placeholder="First Name"
                  className="w-full p-2 border border-gray-300 rounded text-sm"
                  required
                />
              </div>
              <div className="w-full sm:w-1/2">
                <label className="block text-gray-700 text-sm mb-1">
                  Priezvisko <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={localFormData.lastName}
                  onChange={handleChange}
                  placeholder="Last Name"
                  className="w-full p-2 border border-gray-300 rounded text-sm"
                  required
                />
              </div>
            </div>
            <div>
              <label className="block text-gray-700 text-sm mb-1">
                Ulica <span className="text-red-500">*</span>
              </label>
              <input
                name="address"
                value={localFormData.address}
                onChange={handleChange}
                placeholder="Adress"
                className="w-full p-2 border border-gray-300 rounded text-sm"
                required
              />
            </div>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-3">
              <div className="w-full sm:w-1/2">
                <label className="block text-gray-700 text-sm mb-1">
                  Mesto <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="city"
                  value={localFormData.city}
                  onChange={handleChange}
                  placeholder="City"
                  className="w-full p-2 border border-gray-300 rounded text-sm"
                  required
                />
              </div>
              <div className="w-full sm:w-1/2">
                <label className="block text-gray-700 text-sm mb-1">
                  PSČ <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="psc"
                  value={localFormData.psc}
                  onChange={handleChange}
                  placeholder="Post code"
                  className="w-full p-2 border border-gray-300 rounded text-sm"
                  required
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-3">
              <div className="w-full sm:w-1/2">
                <label className="block text-gray-700 text-sm mb-1">
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={localFormData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  className="w-full p-2 border border-gray-300 rounded text-sm"
                  required
                />
              </div>
              <div className="w-full sm:w-1/2">
                <label className="block text-gray-700 text-sm mb-1 flex items-center">
                  Telefónne číslo <span className="text-red-500">*</span>
                  <div className="relative group">
                    <GrCircleInformation className="ml-2 cursor-pointer" />
                    <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2 z-10">
                      Zadajte číslo vo formáte (+421XXXXXXXXX)
                    </div>
                  </div>
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={localFormData.phone}
                  onChange={handleChange}
                  placeholder="+421XXXXXXXXX"
                  className={`w-full p-2 border ${phoneError ? 'border-red-500' : 'border-gray-300'} rounded text-sm`}
                  required
                />
              {phoneError && (
                <div className="absolute bg-red-500 text-white text-xs p-2 rounded shadow-md z-10">
                  {phoneError}
                </div>
              )}
              </div>
            </div>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-3">
              <div className="w-full sm:w-3/4">
                <label className="block text-gray-700 text-sm mb-1">
                  Poisťovňa <span className="text-red-500">*</span>
                </label>
                <select
                  name="insurance"
                  value={localFormData.insurance}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded text-sm"
                  required
                >
                  <option value="">Vyberte poisťovňu</option>
                  <option value="27">Union (27)</option>
                  <option value="24">Dôvera (24)</option>
                  <option value="25">Všeobecná zdravotná poisťovňa (25)</option>
                  <option value="Poistnený v EU / EU health insurance">Poistnený v EU / EU health insurance</option>
                  <option value="0">Samoplatca / Cash payment</option>
                </select>
              </div>
              <div className="w-full sm:w-1/4">
                <label className="block text-gray-700 text-sm mb-1 flex items-center">
                  Rodné číslo <span className="text-red-500">*</span>
                  <div className="relative group">
                    <GrCircleInformation className="ml-2 cursor-pointer" />
                    <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2 z-10">
                      Zadajte rodné číslo bez "/" (990201XXXX)
                    </div>
                  </div>
                </label>
                  <input
                    type="text"
                    name="identification_number"
                    value={localFormData.identification_number}
                    onChange={handleChange}
                    placeholder="IDN"
                    className={`w-full p-2 border ${idError ? 'border-red-500' : 'border-gray-300'} rounded text-sm`}
                    required
                />
              {idError && (
                <div className="absolute bg-red-500 text-white text-xs p-2 rounded shadow-md z-10">
                  {idError}
                </div>
              )}
              </div>
            </div>
            <div className="flex flex-col">
              <label className="block text-gray-700 text-sm mb-1">
                Bližšie informácie
              </label>
              <textarea
                name="info"
                value={localFormData.info}
                onChange={handleChange}
                placeholder="More information"
                className="w-full p-2 border border-gray-300 rounded"
                rows={2}
              />
            </div>
            <div className="flex justify-between items-center mt-4">
              <button type="button" className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600" onClick={Back} data-testid="back-test">
                <GrFormPrevious className="w-6 h-6" />
              </button>
              <button
                type="submit"
                className="bg-green-600 text-white py-2 px-5 rounded hover:bg-green-600 text-sm"
              >
                Pokračuj
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReservationForm;
