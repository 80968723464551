import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import dayjs from "dayjs";
import 'dayjs/locale/sk';
import { generateDate, months, formatDate } from "./util/generateDate.js";
import cn from "./util/cn.js";
import { GrFormNext, GrFormPrevious, GrCircleInformation } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
import './Calendar.css';

const priceLabels = {
    registracny_poplatok: "Registračný poplatok",
    rocna_basic: "Ročná karta BASIC",
    rocna_plus: "Ročná karta PLUS",
    rocna_max: "Ročná karta MAX",
    multi_basic: "Multi karta BASIC",
    multi_plus: "Multi karta PLUS",
    multi_max: "Multi karta MAX",
};

function Calendar({
    cenyKariet,
    onDateChange,
    fastPrices,
    onTimeChange,
    selectedDoctor,
    selectedAmbulance,
    onPriceLabelChange,
    onTotalPriceChange,
    onCardPriceChange,
    selectedCalendarId,
    setDiffSum,
}) {
    const navigate = useNavigate();
    const days = ["Po", "Ut", "St", "Št", "Pi"];
    const currentDate = dayjs();
    const [today, setToday] = useState(currentDate);
    const [year, setYear] = useState(currentDate.year());
    const [availability, setAvailability] = useState({ times: [] });
    const [selectedTime, setSelectedTime] = useState(null);
    const [selectDate, setSelectDate] = useState(currentDate);
    const [selectedPriceKey, setSelectedPriceKey] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedRocnaSubType, setSelectedRocnaSubType] = useState('basic');
    const [selectedMultiSubType, setSelectedMultiSubType] = useState('basic');
    const [selectedDuration, setSelectedDuration] = useState(30);
    const [showModal, setShowModal] = useState(false);
    const [fastPrice, setFastPrice] = useState(0);
    const [selectedSpecialPrice, setSelectedSpecialPrice] = useState(null);
    const [datesAvailability, setDatesAvailability] = useState({});

    const years = Array.from({ length: 5 }, (_, i) => currentDate.year() - 1 + i);
    const dates = useMemo(() => generateDate(today.month(), year), [today, year]);

    useEffect(() => {
        handleDateSelect(selectDate);
    }, [selectedCalendarId]);

    useEffect(() => {
        calculateFastPrice(selectDate);
    }, [selectDate, selectedPriceKey]);

    useEffect(() => {
        if (!selectedDoctor) {
            setDatesAvailability({});
            setAvailability({ times: [], speedType: null });
            return;
        }

        let isCancelled = false;

        const fetchAvailabilityForSelectedDoctor = async () => {
            try {
                const response = await fetch(`https://bezrucova.ccw.sk/bezrucovabackend/appointments/freeByCalendar/${selectedCalendarId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch availability data');
                }

                const availabilityData = await response.json();

                const availabilityMap = {};
                const todayFormatted = currentDate.format('YYYY-MM-DD');

                availabilityData[0].appointments
                    .filter(appointment => appointment.date !== todayFormatted)
                    .forEach(appointment => {
                        availabilityMap[appointment.date] = {
                            times: appointment.times,
                            speedType: appointment.speedType,
                        };
                    });

                if (!isCancelled) {
                    setDatesAvailability(availabilityMap);

                    availabilityMap[todayFormatted] = { times: [], speedType: null };

                    setAvailability(availabilityMap[selectDate.format('YYYY-MM-DD')] || { times: [], speedType: null });
                }
            } catch (error) {
                console.error("Error fetching availability data:", error);
            }
        };

        if (selectedCalendarId) {
            fetchAvailabilityForSelectedDoctor();
        }

        return () => {
            isCancelled = true;
        };
    }, [selectedCalendarId, selectedDoctor]);

    const handleDateSelect = (date) => {
        setSelectDate(date);

        const dateKey = date.format('YYYY-MM-DD');
        const avail = datesAvailability[dateKey] || { times: [], speedType: null };

        if (date.isSame(currentDate, 'day')) {
            setAvailability({ times: [], speedType: null });
        } else {
            setAvailability(avail);
        }

        setSelectedTime(null);
        setSelectedPriceKey(null);
        setSelectedType(null);
        setSelectedRocnaSubType('basic');
        setSelectedMultiSubType('basic');
        setSelectedSpecialPrice(null);
    };

    const toggleModal = () => setShowModal(!showModal);

    useEffect(() => {
        setDatesAvailability({});
        handleDateSelect(selectDate);
    }, [selectedAmbulance]);

    useEffect(() => {
        setDatesAvailability({});
        setSelectedTime(null);
        setSelectedPriceKey(null);
        setAvailability({ times: [], speedType: null });
    }, [selectedDoctor]);

    const getDisabledReason = () => {
        if (!selectedTime) return "Čas vyšetrenia nie je vybratý.";
        if (selectedPriceKey === null) return "Cenová kategória nie je vybratá.";
        return "Informácie sú neúplné.";
    };

    const calculateFastPrice = (date) => {
        if (selectedPriceKey !== null) {
            const priceKey = selectedPriceKey;

            const availabilityForDate = datesAvailability[date.format('YYYY-MM-DD')] || { times: [], speedType: null };
            const speedType = availabilityForDate.speedType;

            let newFastPrice = 0;

            if (speedType === 'express') {
                newFastPrice = parseFloat(fastPrices[`${priceKey}_5day`] || "0");
            } else if (speedType === 'quick') {
                newFastPrice = parseFloat(fastPrices[`${priceKey}_10day`] || "0");
            } else {
                newFastPrice = parseFloat(fastPrices[`${priceKey}_other`] || "0");
            }

            setFastPrice(newFastPrice);
            setDiffSum(newFastPrice);
        }
    };

    const confirmAppointment = () => {
        if (selectedTime && selectDate && selectedPriceKey !== null) {
            let cardPrice;

            if (selectedDoctor && selectedDoctor.name === "MUDr. Dana Hrušovská" && selectedPriceKey === 'registracny_poplatok') {
                cardPrice = 49;
            } else {
                const price = cenyKariet[selectedPriceKey];
                if (price == null) {
                    alert("Zvolená cenová kategória nie je dostupná.");
                    return;
                }
                cardPrice = parseFloat(price) || 0;
            }

            let totalPrice;

            if (selectedSpecialPrice !== null) {
                totalPrice = selectedSpecialPrice;
            } else {
                totalPrice = cardPrice + fastPrice;
            }

            onDateChange(selectDate);
            onTimeChange({ time: selectedTime, duration: selectedDuration });
            onPriceLabelChange(priceLabels[selectedPriceKey]);
            onTotalPriceChange(totalPrice);
            onCardPriceChange(cardPrice);

            navigate('/rezervacia');
        } else {
            alert(getDisabledReason());
        }
    };

    const handleTimeSelect = (timeSlot) => {
        setSelectedTime(timeSlot.time);
        setSelectedDuration(timeSlot.duration);
        setSelectedSpecialPrice(timeSlot.specialPrice || null);
    };

    const handlePriceChange = (type, subType = 'basic') => {
        setSelectedType(type);

        let priceKey = '';
        switch (type) {
            case 'reg':
                priceKey = 'registracny_poplatok';
                break;
            case 'rocna':
                setSelectedRocnaSubType(subType);
                priceKey = `rocna_${subType}`;
                break;
            case 'multi':
                setSelectedMultiSubType(subType);
                priceKey = `multi_${subType}`;
                break;
            default:
                priceKey = null;
        }
        setSelectedPriceKey(priceKey);
    };

    const getBackgroundColorClass = (date) => {
        const availabilityForDate = datesAvailability[date.format('YYYY-MM-DD')] || { times: [], speedType: null };
        const speedType = availabilityForDate.speedType;
        const isToday = date.isSame(currentDate, 'day');
        const isPast = date.isBefore(currentDate, 'day') || isToday;

        if (availabilityForDate.times.length > 0 && !isPast) {
            if (speedType === 'express') {
                return "bg-blue-600";
            } else if (speedType === 'quick') {
                return "bg-blue-400";
            } else {
                return "bg-blue-200";
            }
        }

        return "bg-slate-400";
    };

    const isAppointmentSelectable = selectedTime && selectedPriceKey !== null;

    const handleInfoIconClick = () => {
        toggleModal();
    };

    const getTotalPriceForOption = (priceKey) => {
        if (
            selectedDoctor &&
            (selectedDoctor.name === "MUDr. Iveta Čierna, PhD." ||
                selectedDoctor.name === "MUDr. Dagmar Székyová ") &&
            priceKey.startsWith('multi')
        ) {
            return null;
        }
    
        let cardPrice;
    
        if (
            selectedDoctor &&
            selectedDoctor.name === "MUDr. Dana Hrušovská" &&
            priceKey === 'registracny_poplatok'
        ) {
            cardPrice = 49;
        } else {
            const price = cenyKariet[priceKey];
            if (price == null) {
                return null;
            }
            cardPrice = parseFloat(price) || 0;
        }
    
        let optionFastPrice = 0;
    
        const availabilityForDate =
            datesAvailability[selectDate.format('YYYY-MM-DD')] || {
                times: [],
                speedType: null,
            };
        const speedType = availabilityForDate.speedType;
    
        if (speedType === 'express') {
            optionFastPrice = parseFloat(fastPrices[`${priceKey}_5day`] || '0');
        } else if (speedType === 'quick') {
            optionFastPrice = parseFloat(fastPrices[`${priceKey}_10day`] || '0');
        } else {
            optionFastPrice = parseFloat(fastPrices[`${priceKey}_other`] || '0');
        }
    
        const totalPrice = cardPrice + optionFastPrice;
        return totalPrice;
    };
    
    

    function isRocnaKartaAvailable() {
        return (
            cenyKariet['rocna_basic'] != null ||
            cenyKariet['rocna_plus'] != null ||
            cenyKariet['rocna_max'] != null
        );
    }

    return (
        <div className="bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: "url('/pictures/background.jpg')" }}>
            <div className="p-4 sm:p-8 bg-slate-100 relative w-full h-full">
                <div className="w-full h-24 flex justify-center bg-opacity-80 bg-slate-100 p-3">
                    <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold text-black mb-4">
                        Kalendár voľných termínov{" "}
                        {selectedDoctor ? `pre lekára: ${selectedDoctor.name}` : ""}
                    </h1>
                </div>
                <div className="flex flex-col sm:flex-row sm:gap-10 sm:divide-x divide-black justify-center items-center rounded-lg p-4 sm:p-8">
                    <div className="w-full sm:w-auto">
                        {/* Date section */}
                        <div className="flex justify-between items-center mb-4 text-black">
                            <div className="flex items-center">
                                <select
                                    value={year}
                                    onChange={(e) => {
                                        setYear(parseInt(e.target.value));
                                        setToday(today.year(parseInt(e.target.value)));
                                    }}
                                    className="select-none font-semibold cursor-pointer bg-slate-100 border border-black text-black rounded-lg p-1"
                                >
                                    {years.map(y => (
                                        <option key={y} value={y}>
                                            {y}
                                        </option>
                                    ))}
                                </select>
                                <h1 className="select-none font-semibold inline ml-2">
                                    {months[today.month()]}
                                </h1>
                            </div>
                            <div className="flex gap-2 items-center font-semibold">
                                <GrFormPrevious
                                    className="w-5 h-5 cursor-pointer hover:scale-105 transition-all"
                                    onClick={() => {
                                        if (today.month() === 0) {
                                            setToday(today.subtract(1, 'year').month(11));
                                            setYear(year - 1);
                                        } else {
                                            setToday(today.subtract(1, 'month'));
                                        }
                                    }}
                                />
                                <h1
                                    className="cursor-pointer hover:scale-105 transition-all"
                                    onClick={() => {
                                        setToday(currentDate);
                                        setYear(currentDate.year());
                                    }}
                                >
                                    Dnes
                                </h1>
                                <GrFormNext
                                    className="w-5 h-5 cursor-pointer hover:scale-105 transition-all"
                                    onClick={() => {
                                        if (today.month() === 11) {
                                            setToday(today.add(1, 'year').month(0));
                                            setYear(year + 1);
                                        } else {
                                            setToday(today.add(1, 'month'));
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-5 border-b border-black">
                            {days.map((day, index) => (
                                <h1 key={index} className="text-sm text-center h-10 w-14 grid place-content-center text-black select-none">
                                    {day}
                                </h1>
                            ))}
                        </div>
                        <div className="grid grid-cols-5 grid-rows-6">
                            {dates.slice(0, 25).map(({ date, currentMonth }, index) => {
                                const isPast = date.isBefore(currentDate, 'day');
                                const isSelected = selectDate.isSame(date, 'day');
                                const bgColorClass = !isPast ? (
                                    isSelected
                                        ? "bg-green-600 text-white"
                                        : getBackgroundColorClass(date)
                                ) : "text-black cursor-not-allowed bg-slate-400";

                                const hoverClass = !isPast && !isSelected ? (datesAvailability[date.format('YYYY-MM-DD')]?.times.length > 0 ? "hover:bg-green-400" : "hover:bg-red-300") : "";

                                return (
                                    <div key={index} className={"p-1 text-center h-14 grid place-content-center text-sm border-t border-black"}>
                                        <h1
                                            className={cn(
                                                currentMonth ? "" : "text-white",
                                                bgColorClass,
                                                "h-12 w-12 grid place-content-center transition-all cursor-pointer select-none border border-black",
                                                isPast && "cursor-not-allowed hover:bg-red-300",
                                                !isSelected && hoverClass
                                            )}
                                            onClick={() => {
                                                if (!isPast) {
                                                    handleDateSelect(date);
                                                }
                                            }}
                                        >
                                            {date.date()}
                                        </h1>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="flex flex-col h-auto max-h-96 overflow-y-auto sm:px-5 text-black w-full sm:w-3/5 min-w-0 mb-8">
                        <div className="mb-4">
                            <h1 className="font-bold text-sm sm:text-base md:text-lg">
                                Voľné termíny na deň: {formatDate(selectDate)}
                            </h1>
                                <div className="mt-4">
                                {availability.times.length > 0 ? (
                                    <div className="max-h-60 overflow-y-auto">
                                    <div className="grid grid-cols-2 gap-2">
                                        {availability.times
                                        .sort((a, b) => {
                                            const timeA = a.time.split(":").map(Number);
                                            const timeB = b.time.split(":").map(Number);
                                            return timeA[0] - timeB[0] || timeA[1] - timeB[1];
                                        })
                                        .map((timeSlot, index) => (
                                            <div
                                            key={index}
                                            className={cn(
                                                "cursor-pointer p-2 rounded border border-black flex items-center justify-center transition-all",
                                                selectedTime === timeSlot.time
                                                ? "bg-green-600 text-white"
                                                : "bg-white hover:bg-green-400 hover:text-white"
                                            )}
                                            onClick={() => handleTimeSelect(timeSlot)}
                                            >
                                            {timeSlot.time.slice(0, 5)}
                                            {timeSlot.specialPrice !== undefined && (
                                                <span className="ml-1 text-xs text-green-500"> {timeSlot.specialPrice}€</span>
                                            )}
                                            </div>
                                        ))}
                                    </div>
                                    </div>
                                ) : (
                                    <p className="text-gray-600">Žiadne termíny na tento deň.</p>
                                )}
                                </div>

                        </div>
                        <div className="sm:mb-4">
                            <div className="flex items-center justify-center">
                                <h1 className="font-bold text-sm sm:text-base md:text-lg">
                                    Vyberte si službu:
                                </h1>
                                <div className="relative group ml-2">
                                    <GrCircleInformation
                                        data-testid="service-info-icon"
                                        className="text-black cursor-pointer w-5 h-5"
                                        onClick={handleInfoIconClick}
                                    />
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="flex items-center">
                                    <input
                                        type="radio"
                                        id="reg"
                                        name="service"
                                        checked={selectedType === 'reg'}
                                        onChange={() => handlePriceChange('reg')}
                                        className="custom-radio"
                                    />
                                    <label htmlFor="reg" className={`cursor-pointer font-semibold ${selectedType === 'reg' ? 'text-green-600' : 'text-black'} text-xs sm:text-sm md:text-base`}>
                                        Jednorazový registračný poplatok :
                                    </label>
                                    <span className={`ml-1 font-semibold text-xs sm:text-sm md:text-base ${selectedType === 'reg' ? 'text-green-600' : 'text-black'}`}>
                                        {selectedSpecialPrice !== null ? `${selectedSpecialPrice}€` : `${getTotalPriceForOption('registracny_poplatok')}€`}
                                    </span>
                                </div>

                                {/* Ročná karta */}
                                {isRocnaKartaAvailable() && (
                                    <div className="flex items-center mt-2">
                                        <input
                                            type="radio"
                                            id="rocna"
                                            name="service"
                                            checked={selectedType === 'rocna'}
                                            onChange={() => handlePriceChange('rocna', selectedRocnaSubType)}
                                            className="custom-radio"
                                        />
                                        <label htmlFor="rocna" className={`cursor-pointer font-semibold ${selectedType === 'rocna' ? 'text-green-600' : 'text-black'} text-xs sm:text-sm md:text-base`}>
                                            Ročná karta
                                        </label>
                                        <select
                                            className="ml-2 p-1 bg-slate-100 border border-black rounded text-xs sm:text-sm md:text-base"
                                            value={selectedRocnaSubType}
                                            onChange={(e) => handlePriceChange('rocna', e.target.value)}
                                        >
                                            <option value="basic">BASIC</option>
                                            <option value="plus">PLUS</option>
                                            <option value="max">MAX</option>
                                        </select>
                                        <span className={`ml-2 font-semibold ${selectedType === 'rocna' ? 'text-green-600' : 'text-black'} text-xs sm:text-sm md:text-base`}>
                                            : {getTotalPriceForOption(`rocna_${selectedRocnaSubType}`) != null ? `${getTotalPriceForOption(`rocna_${selectedRocnaSubType}`)}€` : 'N/A'}
                                        </span>
                                    </div>
                                )}
                                {/* Multi Karta */}
                                <div className="flex items-center mt-2">
                                    <input
                                        type="radio"
                                        id="multi"
                                        name="service"
                                        checked={selectedType === 'multi'}
                                        onChange={() => handlePriceChange('multi', selectedMultiSubType)}
                                        className="custom-radio"
                                        disabled={
                                            selectedDoctor &&
                                            (selectedDoctor.name === 'MUDr. Iveta Čierna, PhD.' ||
                                                selectedDoctor.name === 'MUDr. Dagmar Székyová ')
                                        }
                                    />
                                    <label
                                        htmlFor="multi"
                                        className={`cursor-pointer font-semibold ${
                                            selectedType === 'multi' ? 'text-green-600' : 'text-black'
                                        } text-xs sm:text-sm md:text-base`}
                                    >
                                        Ročná karta MULTI:
                                    </label>
                                    {selectedDoctor &&
                                    (selectedDoctor.name === 'MUDr. Iveta Čierna, PhD.' ||
                                        selectedDoctor.name === 'MUDr. Dagmar Székyová ') ? (
                                        <span className="ml-2 font-semibold text-xs sm:text-sm md:text-base">
                                            N/A
                                        </span>
                                    ) : (
                                        <>
                                            <select
                                                className="ml-2 p-1 bg-slate-100 border border-black rounded text-xs sm:text-sm md:text-base"
                                                value={selectedMultiSubType}
                                                onChange={(e) =>
                                                    handlePriceChange('multi', e.target.value)
                                                }
                                            >
                                                <option value="basic">BASIC</option>
                                                <option value="plus">PLUS</option>
                                                <option value="max">MAX</option>
                                            </select>
                                            <span
                                                className={`ml-2 font-semibold ${
                                                    selectedType === 'multi' ? 'text-green-600' : 'text-black'
                                                } text-xs sm:text-sm md:text-base`}
                                            >
                                                :{' '}
                                                {getTotalPriceForOption(`multi_${selectedMultiSubType}`) != null
                                                    ? `${getTotalPriceForOption(
                                                        `multi_${selectedMultiSubType}`
                                                    )}€`
                                                    : 'N/A'}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Legend */}
                <div className="sm:absolute text-xs sm:text-sm md:text-base bottom-5 left-4 flex flex-col">
                    <div className="flex items-center">
                        <div className="w-3 h-3 bg-blue-600 mr-2 flex-shrink-0"></div>
                        <span className="text-black font-semibold">Expresný termín do 5 pracovných dní (príplatok {fastPrices['registracny_poplatok_5day']} Euro k bežnej cene alebo karte)</span>
                        <div className="relative group ml-2">
                        <GrCircleInformation
                            data-testid="legend-info-icon"
                            className="text-black cursor-pointer w-5 h-5"
                            onClick={handleInfoIconClick}
                            />
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="w-3 h-3 bg-blue-400 mr-2 flex-shrink-0"></div>
                        <span className="text-black font-semibold">Rýchly termín do 10 pracovných dní (príplatok {fastPrices['registracny_poplatok_10day']} Euro k bežnej cene alebo karte)</span>
                    </div>
                    <div className="flex items-center">
                        <div className="w-3 h-3 bg-blue-200 mr-2 flex-shrink-0"></div>
                        <span className="text-black font-semibold">Voľné termíny</span>
                    </div>
                </div>

                <div className="relative mt-4 sm:absolute sm:bottom-4 sm:right-4 flex justify-center sm:justify-end w-full">
                    <button
                        className={cn(
                            "py-2 px-5 rounded transition-all",
                            isAppointmentSelectable ? "bg-green-600 text-white hover:shadow-md" : "bg-green-300 text-gray-500 cursor-not-allowed"
                        )}
                        onClick={confirmAppointment}
                    >
                        Pokračuj
                    </button>
                </div>

                {showModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div
                            className="bg-white p-6 rounded-lg shadow-lg relative"
                            style={{
                                width: 'auto',
                                height: 'auto',
                                maxWidth: '90%',
                                maxHeight: '90%',
                                overflowY: 'auto'
                            }}
                        >
                            <button
                                data-testid="modal-close-button"
                                className="absolute top-2 right-2 text-black hover:text-red-500"
                                onClick={toggleModal}
                            >
                                <AiOutlineClose className="w-6 h-6" />
                            </button>
                            <h2 className="text-lg font-bold mb-4">Informácie o službách</h2>
                            <p>
                                V nasledujúcom kroku si, prosím, vyberte jednu z našich služieb.
                            </p>
                            <p>
                                <strong>Jednorazový registračný poplatok </strong>
                                vo výške 69 eur zahŕňa jednorazové
                                vstupné vyšetrenie u vybraného lekára.
                            </p>
                            <p className="mt-1">
                                V prípade záujmu o expresný termín do 5 pracovných dní sa Vám pripočíta k jednorazovému
                                vstupnému vyšetreniu príplatok 119 eur, v prípade záujmu o rýchly termín do 10 pracovných
                                dní sa Vám pripočíta k jednorazovému vyšetreniu príplatok 59 eur.
                            </p>
                            <p className="mt-2">
                                <strong>Ročné karty za nadštandardné služby</strong> sú dostupné v troch úrovniach - BASIC, PLUS a MAX.
                                Ročné karty môžete využiť iba v jednom medicínskom centre a obsahujú okrem iných výhod (napr. rýchle termíny do 10 dní v cene ročnej karty PLUS alebo expresné termíny do 5 dní v cene ročnej karty MAX) aj poplatky za vstupné a kontrolné vyšetrenia.
                                Ak potrebujete starostlivosť viacerých lekárov, sú v ponuke <strong>Ročné karty MULTI</strong>, ktoré Vám zaručujú možnosť objednať sa v ktoromkoľvek medicínskom centre Polikliniky Bezručova.
                                Takisto sú dostupné v troch úrovniach BASIC, PLUS a MAX.
                            </p>
                            <p className="mt-4">
                                Viac info nájdete na{" "}
                                <a
                                    href="https://www.poliklinikabezrucova.sk"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 underline"
                                >
                                    www.poliklinikabezrucova.sk
                                </a>{" "}
                                alebo v{" "}
                                <a
                                    href="https://gastro.notion.site/Internistick-centrum-Bezru-ova-Intern-ambulancia-4d4f0fd4d6974ae994e3e68be2b2b1ea?pvs=4"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 underline"
                                >
                                    cenníku
                                </a>.
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Calendar;
