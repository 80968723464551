import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { GrFormPrevious } from "react-icons/gr";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AdminAdd() {
  const [centers, setCenters] = useState([]);
  const [calendars, setCalendars] = useState([]);
  const [newDoctor, setNewDoctor] = useState({
    typeformUrl: '',
    name: '',
    specialization: '',
    centerId: '',
    expressAppointmentEnabled: false,
    calendarId: '',
    notificationEmailAddress: '',
    infoBtnText: '',
    infoBtnLink: ''
  });

  const [newCenter, setNewCenter] = useState({
    meno: ''
  });
  
  const navigate = useNavigate();
  const baseURL = 'https://bezrucova.ccw.sk/bezrucovabackend';
  const authToken = localStorage.getItem('authToken');

  useEffect(() => {
    if (!authToken) {
        console.error('No auth token found. Please login.');
        alert('Chyba: Prosím prihláste sa');
        return;
    }
    fetch(`${baseURL}/centers/getByPermission`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setCenters(data);
      })
      .catch(error => console.error('Error fetching centers:', error));
  }, []);

  useEffect(() => {
    if (!authToken) {
        console.error('No auth token found. Please login.');
        alert('Chyba: Prosím prihláste sa');
        return;
    }
    fetch(`${baseURL}/doctors/getByPermission`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
    })
      .then(response => response.json())
      .catch(error => console.error('Error fetching doctors:', error));
  }, []);

  useEffect(() => {
    if (!authToken) {
        console.error('No auth token found. Please login.');
        alert('Chyba: Prosím prihláste sa');
        return;
    }
    fetch(`${baseURL}/calendars/available`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch calendars');
        }
        return response.json();
      })
      .then(data => {
        setCalendars(data);
      })
      .catch(error => console.error('Error fetching calendars:', error));
  }, []);

  const handleNewDoctorInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewDoctor((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleNewCenterInputChange = (e) => {
    const { name, value } = e.target;
    setNewCenter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const confirmNewDoctor = async () => {
    if (!authToken) {
      console.error('No auth token found. Please login.');
      alert('Chyba: Prosím prihláste sa');
      return;
    }

    if (!newDoctor.name.trim()) {
      toast.error('Prosím, vyplňte meno lekára.');
      return;
    }
    if (!newDoctor.specialization.trim()) {
      toast.error('Prosím, vyplňte špecializáciu lekára.');
      return;
    }
    if (!newDoctor.centerId) {
      toast.error('Prosím, vyberte centrum.');
      return;
    }
    if (!newDoctor.calendarId) {
      toast.error('Prosím, vyberte kalendár.');
      return;
    }
    if (!newDoctor.typeformUrl.trim()) {
      toast.error('Prosím, vyplňte Typeform URL.');
      return;
    }
    if (!newDoctor.notificationEmailAddress.trim()) {
      toast.error('Prosím, vyplňte notifikačný email.');
      return;
    }
    if (!newDoctor.infoBtnLink.trim()) {
      toast.error('Prosím, vyplňte link ku tlačidlu.');
      return;
    }
    if (!newDoctor.infoBtnText.trim()) {
      toast.error('Prosím, vyplňte text ku tlačidlu.');
      return;
    }

    try {
      const response = await fetch(`${baseURL}/doctors`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify(newDoctor),
      });

      if (!response.ok) {
        throw new Error('Network response not ok');
      }

      const data = await response.json();

      fetch(`${baseURL}/doctors/getByPermission`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
      })
        .then(response => response.json())
        .catch(error => console.error('Error fetching doctors:', error));

      setNewDoctor({
        typeformUrl: '',
        name: '',
        specialization: '',
        centerId: '',
        expressAppointmentEnabled: false,
        calendarId: '',
        notificationEmailAddress: '',
        infoBtnText: '',
        infoBtnLink: ''
      });

      toast.success('Lekár bol úspešne pridaný.');

      if (!newDoctor.expressAppointmentEnabled) {
        const doctorId = data.doctorId;

        const pricesData = {
          vv: [69, 0, 0],
          basic: [0, 0, 0],
          plus: [0, 0, 0],
          max: [0, 0, 0],
        };

        const pricesResponse = await fetch(`${baseURL}/prices/${doctorId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
          body: JSON.stringify(pricesData),
        });

        if (!pricesResponse.ok) {
          throw new Error('Failed to update prices');
        }

      }
    } catch (error) {
      console.error('Error adding doctor or updating prices:', error);
      toast.error('Chyba pri pridávaní lekára alebo nastavovaní cien.');
    }
  };

  const confirmNewCenter = () => {
    if (!authToken) {
      console.error('No auth token found. Please login.');
      alert('Chyba: Prosím prihláste sa');
      return;
    }

    if (!newCenter.meno.trim()) {
      toast.error('Prosím, vyplňte názov centra.');
      return;
    }

    fetch(`${baseURL}/centers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        meno: newCenter.meno
      }),
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response not ok');
      })
      .then(data => {
        if (!authToken) {
            console.error('No auth token found. Please login.');
            alert('Chyba: Prosím prihláste sa');
            return;
        }
        fetch(`${baseURL}/centers/getByPermission`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
        })
          .then(response => response.json())
          .then(data => {
            setCenters(data);
          })
          .catch(error => console.error('Error fetching centers:', error));
        setNewCenter({
          meno: ''
        });
        toast.success('Centrum bolo úspešne pridané.');
      })
      .catch(error => {
        console.error('Error adding center:', error);
        toast.error('Chyba pri pridávaní centra.');
      });
  };

  const Back = () => {
    navigate('/login');
  };

  return (
    <div className="bg-cover bg-center flex justify-center" style={{ backgroundImage: "url('/pictures/background.jpg')" }}>
      <div className="p-4 sm:p-8 bg-slate-100 relative w-full flex flex-col">
      <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
      <div className="w-full flex justify-center bg-opacity-80 bg-slate-100 p-1">
      </div>
        <div className="flex flex-col sm:flex-row sm:gap-4 justify-between items-start rounded-lg p-4 sm:p-8">
          {/* Left - Pridaj lekara */}
          <div className="flex flex-col text-black w-full">
            <h2
              className="text-lg font-bold mb-4"
              data-testid="add-doctor-heading"
            >
              Pridaj lekára
            </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">

              <div className="mb-2">
                <label className="block mb-1 font-semibold" htmlFor="doctor-name">Meno:</label>
                <input
                  id="doctor-name"
                  type="text"
                  name="name"
                  value={newDoctor.name}
                  onChange={handleNewDoctorInputChange}
                  className="w-full border border-gray-400 p-2 rounded"
                  data-testid="doctor-name-input"
                />
              </div>

              <div className="mb-2">
                <label className="block mb-1 font-semibold" htmlFor="doctor-center">Centrum:</label>
                <select
                  id="doctor-center"
                  name="centerId"
                  value={newDoctor.centerId}
                  onChange={handleNewDoctorInputChange}
                  className="w-full border border-gray-400 p-2 rounded"
                  data-testid="doctor-center-select"
                >
                  <option value="">-- Vyberte centrum --</option>
                  {centers.map((ambulance) => (
                    <option key={ambulance.centerId} value={ambulance.centerId}>
                      {ambulance.name} - {ambulance.centerId}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-2">
                <label className="block mb-1 font-semibold" htmlFor="doctor-typeform-url">Typeform Url:</label>
                <input
                  id="doctor-typeform-url"
                  type="text"
                  name="typeformUrl"
                  value={newDoctor.typeformUrl}
                  onChange={handleNewDoctorInputChange}
                  className="w-full border border-gray-400 p-2 rounded"
                  data-testid="doctor-typeform-url-input"
                />
              </div>

              <div className="mb-2">
                <label className="block mb-1 font-semibold" htmlFor="doctor-calendar-id">Kalendár:</label>
                <select
                  id="doctor-calendar-id"
                  name="calendarId"
                  value={newDoctor.calendarId}
                  onChange={handleNewDoctorInputChange}
                  className="w-full border border-gray-400 p-2 rounded"
                  data-testid="doctor-calendar-select"
                >
                  <option value="">-- Vyberte kalendár --</option>
                  {calendars.map((calendar) => (
                    <option key={calendar.calendarId} value={calendar.calendarId}>
                      {calendar.calendarId} - {calendar.calendarName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-2">
                <label className="block mb-1 font-semibold" htmlFor="doctor-specialization">Špecializácia:</label>
                <input
                  id="doctor-specialization"
                  type="text"
                  name="specialization"
                  value={newDoctor.specialization}
                  onChange={handleNewDoctorInputChange}
                  className="w-full border border-gray-400 p-2 rounded"
                  data-testid="doctor-specialization-input"
                />
              </div>

              <div className="mb-2">
                <label className="block mb-1 font-semibold" htmlFor="doctor-info-btn-text">Email - text k buttonu:</label>
                <input
                  id="doctor-info-btn-text"
                  type="text"
                  name="infoBtnText"
                  value={newDoctor.infoBtnText}
                  onChange={handleNewDoctorInputChange}
                  className="w-full border border-gray-400 p-2 rounded"
                  data-testid="doctor-info-btn-text-input"
                />
              </div>

              <div className="mb-2">
                <label className="block mb-1 font-semibold" htmlFor="doctor-notification-email">Notifikačný email:</label>
                <input
                  id="doctor-notification-email"
                  type="text"
                  name="notificationEmailAddress"
                  value={newDoctor.notificationEmailAddress}
                  onChange={handleNewDoctorInputChange}
                  className="w-full border border-gray-400 p-2 rounded"
                  data-testid="doctor-notification-email-input"
                />
              </div>

              <div className="mb-2">
                <label className="block mb-1 font-semibold" htmlFor="doctor-info-btn-link">Email - link k buttonu:</label>
                <input
                  id="doctor-info-btn-link"
                  type="text"
                  name="infoBtnLink"
                  value={newDoctor.infoBtnLink}
                  onChange={handleNewDoctorInputChange}
                  className="w-full border border-gray-400 p-2 rounded"
                  data-testid="doctor-info-btn-link-input"
                />
              </div>

              <div className="flex items-center">
                <input
                  id="doctor-express-appointment"
                  type="checkbox"
                  name="expressAppointmentEnabled"
                  checked={newDoctor.expressAppointmentEnabled}
                  onChange={handleNewDoctorInputChange}
                  className="mr-2 w-4 h-4"
                  data-testid="doctor-express-appointment-checkbox"
                />
                <label className="flex items-center text-xl" htmlFor="doctor-express-appointment">
                  Expresné ceny
                </label>
              </div>

            </div>

            <div className="sm:col-span-2 flex justify-center">
              <button
                className="bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600 mt-4 w-1/2"
                onClick={confirmNewDoctor}
                data-testid="add-doctor-button"
              >
                Pridaj lekára
              </button>
            </div>
          </div>

          {/* Divider */}
          <div className="w-px bg-black mx-1 self-stretch"></div>

          {/* Right - Pridaj centrum */}
          <div className="flex flex-col text-black w-full sm:w-1/3">
            <h2
              className="text-lg font-bold mb-4"
              data-testid="add-center-heading"
            >
              Pridaj centrum
            </h2>

            <div className="mb-4">
              <label className="block mb-1 font-semibold" htmlFor="center-name">Názov centra:</label>
              <input
                id="center-name"
                type="text"
                name="meno"
                value={newCenter.meno}
                onChange={handleNewCenterInputChange}
                className="w-full border border-gray-400 p-2 rounded"
                data-testid="center-name-input"
              />
            </div>

            <button
              onClick={confirmNewCenter}
              className="bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600"
              data-testid="add-center-button"
            >
              Pridaj centrum
            </button>
          </div>
        </div>

        {/* Bottom - Buttons */}
        <div className="relative mt-4 sm:absolute sm:bottom-4 sm:left-4 flex justify-center sm:justify-start">
          <button
            className="bg-blue-600 mr-5 text-white p-4 rounded-full"
            onClick={Back}
            data-testid="back-button"
          >
            <GrFormPrevious className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminAdd;
