import React from 'react';
import DoctorsHospitals from './DoctorsHospitals';
import Calendar from '../calendar/Calendar';

const InfoSection = ({ cenyKariet, selectedCalendarId, onDoctorSelect, fastPrices, onDateChange, onTimeChange, selectedDoctor, selectedAmbulance, onAmbulanceSelect, onPriceLabelChange, onTotalPriceChange, setDiffSum, onCardPriceChange }) => {
    return (
        <div className="justify-center items-center p-4 sm:p-8 w-full max-w-screen-lg mx-auto">
            <div className="bg-white bg-opacity-60 p-4 sm:p-8 w-full h-auto text-center">
                <h1
                    style={{
                        fontFamily: 'Poppins',
                        fontWeight: 500,
                        fontStyle: 'normal',
                        color: 'rgb(0, 0, 0)',
                    }}
                    className="mb-4 text-3xl sm:text-3xl lg:text-4xl xl:text-4xl"
                >
                    Objednajte sa online na vstupné vyšetrenie
                </h1>
                <p
                    style={{
                        fontWeight: 400,
                        fontFamily: 'Poppins',
                        color: 'rgb(0, 0, 0)',
                        fontStyle: 'normal',
                    }}
                    className="mb-6 text-base sm:text-lg lg:text-xl xl:text-1xl"
                >
                    Vyberte si svoje medicínske centrum a svojho lekára.
                    <br />
                    Po výbere termínu, prosím, pokračujte vyplnením formulára.
                    <br />
                </p>
                <div className="w-full">
                    <DoctorsHospitals 
                        onDoctorSelect={onDoctorSelect} 
                        onAmbulanceSelect={onAmbulanceSelect} 
                    />
                </div>
                <div className="mt-4 w-full">
                    <Calendar
                        onDateChange={onDateChange}
                        onTimeChange={onTimeChange}
                        selectedDoctor={selectedDoctor}
                        selectedAmbulance={selectedAmbulance}
                        onPriceLabelChange={onPriceLabelChange}
                        onTotalPriceChange={onTotalPriceChange}
                        onCardPriceChange={onCardPriceChange}
                        setDiffSum={setDiffSum}
                        fastPrices={fastPrices}
                        selectedCalendarId={selectedCalendarId}
                        cenyKariet={cenyKariet}
                    />
                </div>
                <div className="mt-6">
                    <a
                        href="https://www.poliklinikabezrucova.sk"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            fontWeight: 'bold',
                            fontFamily: 'Poppins',
                            color: 'rgb(0, 0, 0)',
                            textDecoration: 'none',
                        }}
                        className="text-lg sm:text-xl lg:text-2xl"
                    >
                        www.poliklinikabezrucova.sk
                    </a>
                </div>
            </div>
        </div>
    );
};

export default InfoSection;
