import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import dayjs from "dayjs";
import 'dayjs/locale/sk';
import { generateDate, months, formatDate } from "../../calendar/util/generateDate.js";
import cn from "../../calendar/util/cn.js";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS

function AdminModify({
    selectedDoctor,
    selectedAmbulance,
    selectedCalendarId,
}) {
    const baseURL = 'https://bezrucova.ccw.sk/bezrucovabackend';
    const navigate = useNavigate();
    const days = ["Po", "Ut", "St", "Št", "Pi"];
    const currentDate = dayjs();
    const [today, setToday] = useState(currentDate);
    const [year, setYear] = useState(currentDate.year());
    const [availability, setAvailability] = useState({ times: [], prices: [], fastPrices: [] });
    const [selectDate, setSelectDate] = useState(currentDate);
    const [timeSlotStatusByDate, setTimeSlotStatusByDate] = useState({});
    const [datesAvailability, setDatesAvailability] = useState({});
    const [selectedTimesByDate, setSelectedTimesByDate] = useState({});

    const years = Array.from({ length: 5 }, (_, i) => currentDate.year() - 1 + i);
    const dates = useMemo(() => generateDate(today.month(), year), [today, year]);

    useEffect(() => {
        handleDateSelect(currentDate);
    }, [selectedCalendarId]);

    const handleDateSelect = (date) => {
        setSelectDate(date);
        const dateKey = date.format('YYYY-MM-DD');
        const avail = datesAvailability[dateKey] || { times: [], prices: [], fastPrices: [] };
        setAvailability(avail);
    };

    useEffect(() => {
        setDatesAvailability({});
        setSelectedTimesByDate({});
        handleDateSelect(currentDate);
    }, [selectedAmbulance]);

    useEffect(() => {
        setDatesAvailability({});
        setSelectedTimesByDate({});
        setAvailability({ times: [], prices: [], fastPrices: [] });
        setTimeSlotStatusByDate({});
    }, [selectedDoctor]);

    useEffect(() => {
        if (!selectedDoctor) {
            setDatesAvailability({});
            setAvailability({ times: [], prices: [], fastPrices: [] });
            return;
        }
    
        let isCancelled = false;
        const authToken = localStorage.getItem('authToken');

        if (!authToken) {
            console.error('No auth token found. Please login.');
            alert('Chyba: Prosím prihláste sa');
            return;
        }
    
        const fetchAvailabilityForSelectedDoctor = async () => {
            try {
                const response = await fetch(`${baseURL}/appointments/${selectedCalendarId}`, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch availability data');
                }
    
                const availabilityData = await response.json();
    
                const availabilityMap = {};
                availabilityData[0].appointments.forEach(appointment => {
                    availabilityMap[appointment.date] = {
                        times: appointment.times,
                        prices: appointment.prices || [],
                        fastPrices: appointment.fastPrices || []
                    };
                });
    
                if (!isCancelled) {
                    setDatesAvailability(availabilityMap);
    
                    const todayFormatted = currentDate.format('YYYY-MM-DD');
                    if (!availabilityMap[todayFormatted]) {
                        availabilityMap[todayFormatted] = { times: [], prices: [], fastPrices: [] };
                    }
    
                    setAvailability(availabilityMap[todayFormatted]);
                }
            } catch (error) {
                console.error("Error fetching availability data:", error);
            }
        };
    
        if (selectedCalendarId) {
            fetchAvailabilityForSelectedDoctor();
        }
    
        return () => {
            isCancelled = true;
        };
    }, [selectedCalendarId, selectedDoctor]);
    

    const handleTimeSelect = (timeSlot) => {
        const dateKey = selectDate.format('YYYY-MM-DD');
        setSelectedTimesByDate((prevSelectedTimes) => {
            const currentSelectedTimes = prevSelectedTimes[dateKey] || [];
            if (currentSelectedTimes.includes(timeSlot.time)) {
                return {
                    ...prevSelectedTimes,
                    [dateKey]: currentSelectedTimes.filter((time) => time !== timeSlot.time),
                };
            } else {
                return {
                    ...prevSelectedTimes,
                    [dateKey]: [...currentSelectedTimes, timeSlot.time],
                };
            }
        });
    };

    const getBackgroundColorClass = (date) => {
        const availabilityForDate = datesAvailability[date.format('YYYY-MM-DD')] || { times: [] };
        if (availabilityForDate.times.length > 0) {
            return "bg-blue-400";
        }
        return "bg-slate-400";
    };


    const handleMakeAvailable = () => {
        const dateKey = selectDate.format('YYYY-MM-DD');
        const selectedTimes = selectedTimesByDate[dateKey] || [];

        if (selectedTimes.length > 0 && selectDate) {
            setTimeSlotStatusByDate((prevStatus) => {
                const updatedStatus = { ...prevStatus };
                selectedTimes.forEach(timeSlot => {
                    updatedStatus[dateKey] = {
                        ...updatedStatus[dateKey],
                        [timeSlot]: "uvolnene",
                    };
                });
                return updatedStatus;
            });
            setSelectedTimesByDate((prevSelectedTimes) => ({
                ...prevSelectedTimes,
                [dateKey]: [],
            }));
        } else {
            toast.error("Vyberte dátum a čas na uvoľnenie termínu.");
        }
    };

    const handleMakeUnavailable = () => {
        const dateKey = selectDate.format('YYYY-MM-DD');
        const selectedTimes = selectedTimesByDate[dateKey] || [];

        if (selectedTimes.length > 0 && selectDate) {
            setTimeSlotStatusByDate((prevStatus) => {
                const updatedStatus = { ...prevStatus };
                selectedTimes.forEach(timeSlot => {
                    updatedStatus[dateKey] = {
                        ...updatedStatus[dateKey],
                        [timeSlot]: "obsadene",
                    };
                });
                return updatedStatus;
            });
            setSelectedTimesByDate((prevSelectedTimes) => ({
                ...prevSelectedTimes,
                [dateKey]: [],
            }));
        } else {
            toast.error("Vyberte dátum a čas na obsadenie termínu.");
        }
    };

    const getTimeSlotClass = (timeSlot) => {
        const dateKey = selectDate.format('YYYY-MM-DD');
        const currentDateStatus = timeSlotStatusByDate[dateKey] || {};

        if (selectedTimesByDate[dateKey]?.includes(timeSlot.time)) {
            return "bg-gray-600 text-white";
        }

        if (currentDateStatus[timeSlot.time] === "uvolnene") {
            return "bg-green-600 text-white";
        } else if (currentDateStatus[timeSlot.time] === "obsadene") {
            return "bg-red-600 text-white";
        }

        if (timeSlot.reserved) {
            return "bg-red-600 text-white";
        } else {
            return "bg-green-600 text-white";
        }
    };

    const handleSave = async () => {
        const appointmentIdsObsadene = [];
        const appointmentIdsUvolnene = [];
    
        Object.entries(timeSlotStatusByDate).forEach(([date, times]) => {
            const availabilityForDate = datesAvailability[date]?.times || [];
            Object.entries(times).forEach(([time, status]) => {
                const appointment = availabilityForDate.find(t => t.time === time);
                if (appointment) {
                    if (status === "obsadene") {
                        appointmentIdsObsadene.push(appointment.appointmentId);
                    }
                    if (status === "uvolnene") {
                        appointmentIdsUvolnene.push(appointment.appointmentId);
                    }
                }
            });
        });

        if (!selectedDoctor) {
            toast.error("Chyba: Prosím vyberte lekára");
            return;
        }

        if (appointmentIdsObsadene.length === 0 && appointmentIdsUvolnene.length === 0) {
            toast.error("Neboli označené žiadne termíny na uloženie.");
            return;
        }
    
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                console.error('No auth token found. You must be logged in.');
                alert('Chyba: Prosím prihláste sa');
                return;
            }
    
            if (appointmentIdsObsadene.length > 0) {
                const responseObsadene = await fetch(`${baseURL}/appointments/updateReservedState`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    },
                    body: JSON.stringify({
                        appointmentIds: appointmentIdsObsadene,
                        newState: "True"
                    }),
                });
    
                if (!responseObsadene.ok) {
                    throw new Error('Failed to update obsadene timeslots');
                }
            }
    
            if (appointmentIdsUvolnene.length > 0) {
                if (!authToken) {
                    console.error('No auth token found. Please login.');
                    alert('Chyba: Prosím prihláste sa');
                    return;
                }
                const responseUvolnene = await fetch(`${baseURL}/appointments/updateReservedState`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    },
                    body: JSON.stringify({
                        appointmentIds: appointmentIdsUvolnene,
                        newState: "False"
                    }),
                });
    
                if (!responseUvolnene.ok) {
                    throw new Error('Failed to update uvolnene timeslots');
                }
            }
    
            toast.success("Termíny boli úspešne zmenené");
    
            setDatesAvailability((prev) => {
                const updated = { ...prev };
                Object.entries(timeSlotStatusByDate).forEach(([date, times]) => {
                    if (!updated[date]) {
                        updated[date] = { times: [], prices: [], fastPrices: [] };
                    }
                    Object.entries(times).forEach(([time, status]) => {
                        const timeSlot = updated[date].times.find(t => t.time === time);
                        if (timeSlot) {
                            timeSlot.reserved = (status === "obsadene");
                        }
                    });
                });
                return updated;
            });
    
            setTimeSlotStatusByDate({});
            setSelectedTimesByDate({});
            handleDateSelect(selectDate);
        } catch (error) {
            console.error("Error saving timeslot updates:", error);
            toast.error("Nepodarilo sa uložiť zmeny. Skúste to znova.");
        }
    };
    
    const Back = () => {
        navigate('/login');
    };

    return (
        <div className="bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: "url('/pictures/background.jpg')" }}>
            <div className="p-4 sm:p-8 bg-slate-100 relative w-full h-full">
            <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="flex flex-col sm:flex-row sm:gap-6 justify-between items-start rounded-lg p-4 sm:p-8">
                    {/* Left - Calendar */}
                    <div className="w-full sm:w-auto sm:flex-shrink-0">
                        {/* Date */}
                        <div className="flex justify-between items-center mb-4 text-black">
                            <div className="flex items-center">
                                <select
                                    value={year}
                                    onChange={(e) => {
                                        setYear(parseInt(e.target.value));
                                        setToday(today.year(parseInt(e.target.value)));
                                    }}
                                    className="select-none font-semibold cursor-pointer bg-slate-100 border border-black text-black rounded-lg p-1"
                                >
                                    {years.map(y => (
                                        <option key={y} value={y}>
                                            {y}
                                        </option>
                                    ))}
                                </select>
                                <h1 className="select-none font-semibold inline ml-2">
                                    {months[today.month()]}
                                </h1>
                            </div>
                            <div className="flex gap-2 items-center font-semibold">
                            <GrFormPrevious
                                className="w-5 h-5 cursor-pointer hover:scale-105 transition-all"
                                onClick={() => {
                                    if (today.month() === 0) {
                                        setToday(today.subtract(1, 'year').month(11));
                                        setYear(year - 1);
                                    } else {
                                        setToday(today.subtract(1, 'month'));
                                    }
                                }}
                            />
                                <h1
                                    className="cursor-pointer hover:scale-105 transition-all"
                                    onClick={() => {
                                        setToday(currentDate);
                                        setYear(currentDate.year());
                                    }}>
                                    Dnes
                                </h1>
                                <GrFormNext
                                    className="w-5 h-5 cursor-pointer hover:scale-105 transition-all"
                                    onClick={() => {
                                        if (today.month() === 11) {
                                            setToday(today.add(1, 'year').month(0));
                                            setYear(year + 1);
                                        } else {
                                            setToday(today.add(1, 'month'));
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-5 border-b border-black">
                            {days.map((day, index) => (
                                <h1 key={index} className="text-sm text-center h-10 w-14 grid place-content-center text-black select-none">
                                    {day}
                                </h1>
                            ))}
                        </div>
                        <div className="grid grid-cols-5 grid-rows-6">
                            {dates.slice(0, 25).map(({ date, currentMonth, today: isToday }, index) => {
                                const isPast = date.isBefore(currentDate, 'day');
                                const isSelected = selectDate.isSame(date, 'day');
                                const bgColorClass = !isPast ? (
                                    isSelected
                                        ? "bg-green-600 text-white"
                                        : getBackgroundColorClass(date)
                                ) : "text-black cursor-not-allowed bg-slate-400";

                                const hoverClass = !isPast && !isSelected ? (datesAvailability[date.format('YYYY-MM-DD')]?.times.length > 0 ? "hover:bg-green-400" : "hover:bg-red-300") : "";

                                return (
                                    <div key={index} className={"p-1 text-center h-14 grid place-content-center text-sm border-t border-black"}>
                                        <h1
                                            className={cn(
                                                currentMonth ? "" : "text-white",
                                                bgColorClass,
                                                "h-12 w-12 grid place-content-center transition-all cursor-pointer select-none border border-black",
                                                isPast && "cursor-not-allowed hover:bg-red-300",
                                                !isSelected && hoverClass
                                            )}
                                            onClick={() => {
                                                if (!isPast) {
                                                    handleDateSelect(date);
                                                }
                                            }}
                                        >
                                            {date.date()}
                                        </h1>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {/* Divider */}
                    <div className="w-px bg-black mx-4 sm:mx-8 self-stretch"></div>

                    {/* Middle - Timeslots */}
                    <div className="flex flex-col max-h-96 overflow-y-auto sm:px-5 text-black w-full sm:w-2/5">
                        <div className="mb-4">
                            <h1 className="font-bold text-sm sm:text-base md:text-lg">
                                Všetky termíny na deň: {formatDate(selectDate)}
                            </h1>
                            <div className="mt-4">
                                {availability.times.length > 0 ? (
                                    <div className="grid grid-cols-2 gap-2">
                                {availability.times
                                    .sort((a, b) => {
                                        const timeA = a.time.split(":").map(Number);
                                        const timeB = b.time.split(":").map(Number);
                                        return timeA[0] - timeB[0] || timeA[1] - timeB[1];
                                    }).map((timeSlot, index) => (
                                            <div
                                                key={index}
                                                className={cn(
                                                    "cursor-pointer p-2 rounded border border-black flex items-center justify-center transition-all",
                                                    getTimeSlotClass(timeSlot)
                                                )}
                                                onClick={() => handleTimeSelect(timeSlot)}
                                            >
                                                {timeSlot.time.slice(0, 5)}
                                            </div>
                                        ))}
                                    </div>
                                ) : (<p className="text-gray-600">Žiadne termíny na tento deň.</p>)}
                            </div>
                        </div>
                    </div>

                    {/* Divider */}
                    <div className="w-px bg-black mx-4 sm:mx-8 self-stretch"></div>

                    {/* Right - Actions */}
                    <div className="flex flex-col h-auto max-h-96 overflow-y-auto sm:px-5 text-black w-full sm:w-1/5">
                        <div className="mb-4 mt-auto">
                            <div className="flex items-center justify-center">
                                <h1 className="font-bold text-sm sm:text-base md:text-lg">
                                    Akcie pre termíny:
                                </h1>
                            </div>
                            <div className="mt-3">
                                <div className="flex flex-col items-center justify-center gap-4">
                                    <button
                                        className="w-full py-2 px-4 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-all"
                                        onClick={handleMakeAvailable}
                                    >
                                        Uvoľniť termín
                                    </button>
                                    <button
                                        className="w-full py-2 px-4 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-all"
                                        onClick={handleMakeUnavailable}
                                    >
                                        Obsadiť termín
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative mt-4 sm:absolute sm:bottom-4 sm:left-4 flex justify-center sm:justify-start">
                    <button className="bg-blue-600 mr-5 text-white p-4 rounded-full" onClick={Back}>
                        <GrFormPrevious className="w-6 h-6" />
                    </button>
                </div>

                <div className="relative mt-4 sm:absolute sm:bottom-4 sm:right-4 flex justify-center sm:justify-end">
                    <button
                        className={"py-3 px-8 rounded transition-all bg-green-600 text-white hover:shadow-md"}
                        onClick={handleSave}>
                        Ulož
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AdminModify;
