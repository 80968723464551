import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AdminData from './AdminData';

const AdminDataInfo = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const userRole = localStorage.getItem('userRole');


    const generateSubmit = async (e) => {
        navigate('/adminGenerate');
    }
    const modifyTimeslotSubmit = async (e) => {
        navigate('/adminModify');
    }
    const administrationPoliklinikaSubmit = async (e) => {
        navigate('/adminPrice');
    }
    const administrationDoctorSubmit = async (e) => {
        navigate('/adminDoctor');
    }
    const addDoctorSubmit = async (e) => {
        navigate('/adminAdd');
    };

    return (
        <div className="flex justify-center items-center p-4 sm:p-8 w-full max-w-screen-xl mx-auto">
            <div className="bg-white bg-opacity-60 p-4 sm:p-8 w-full h-auto text-center">
                <h1
                    style={{
                        fontFamily: 'Poppins',
                        fontWeight: 500,
                        fontStyle: 'normal',
                        color: 'rgb(0, 0, 0)',
                    }}
                    className="mb-4 text-3xl sm:text-3xl lg:text-4xl xl:text-4xl"
                >
                    Administrácia dát pre lekárov a centrá
                </h1>
                <p
                    style={{
                        fontWeight: 400,
                        fontFamily: 'Poppins',
                        color: 'rgb(0, 0, 0)',
                        fontStyle: 'normal',
                    }}
                    className="mb-6 text-base sm:text-lg lg:text-xl xl:text-1xl"
                >
                    Vyberte si lekára alebo centrum, ktoré chcete upraviť. Po vyplnení údajov kliknite na ,,Ulož zmeny doktora“ alebo ,,Ulož zmeny centra“. Ak chcete odstrániť lekára alebo centrum, vyberte ich zo zoznamu a kliknite na ,,Odstráň centrum“ alebo ,,Odstráň lekára“. 
                </p>
                <div className="flex space-x-4 w-full">
                    <button onClick={modifyTimeslotSubmit} className="flex-1 h-12 bg-slate-200 text-black font-medium rounded border-4 border-black flex items-center justify-center">
                        Obsadenie termínov
                    </button>
                    <button onClick={generateSubmit} className="flex-1 h-12 bg-slate-200 text-black font-medium rounded border-4 border-black flex items-center justify-center">
                        Generovanie terminov
                    </button>
                    <button onClick={administrationPoliklinikaSubmit} className="flex-1 h-12 bg-slate-200 text-black font-medium rounded border-4 border-black flex items-center justify-center">
                        Administrácia cien
                    </button>
                    <button onClick={administrationDoctorSubmit} className={`flex-1 h-12 font-medium rounded border-4 border-black flex items-center justify-center ${location.pathname === '/adminDoctor' ? 'bg-green-600 text-white' : 'bg-slate-200 text-black'}`}>
                        Administrácia lekárov
                    </button>
                    <button onClick={addDoctorSubmit} className="flex-1 h-12 bg-slate-200 text-black font-medium rounded border-4 border-black flex items-center justify-center">
                        Pridaj lekára/centrum
                    </button>
                </div>
                <div className="mt-4 w-full">
                    <AdminData/>
                </div>
            </div>
        </div>
    );
};

export default AdminDataInfo;
