import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import dayjs from "dayjs";
import 'dayjs/locale/sk';
import { generateDate, months, formatDate } from "../../calendar/util/generateDate.js";
import cn from "../../calendar/util/cn.js";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS

function AdminPrices({
    selectedDoctor,
    selectedAmbulance,
    selectedCalendarId
}) {
    const baseURL = 'https://bezrucova.ccw.sk/bezrucovabackend';
    const navigate = useNavigate();
    const days = ["Po", "Ut", "St", "Št", "Pi"];
    const currentDate = dayjs();
    const [today, setToday] = useState(currentDate);
    const [year, setYear] = useState(currentDate.year());
    const [availability, setAvailability] = useState({ times: [], prices: [], fastPrices: [] });
    const [selectedTimes, setSelectedTimes] = useState([]);
    const [selectDate, setSelectDate] = useState(currentDate);
    const [datesAvailability, setDatesAvailability] = useState({});
    const [localPrices, setLocalPrices] = useState({});
    const [cenaTerminu, setCenaTerminu] = useState('');
    const [fetchedPrices, setFetchedPrices] = useState(null);
    const [fetchedCardPrices, setFetchedCardPrices] = useState(null);

    // Initialize allowedCards as an empty object
    const [allowedCards, setAllowedCards] = useState({});

    const years = Array.from({ length: 5 }, (_, i) => currentDate.year() - 1 + i);
    const dates = useMemo(() => generateDate(today.month(), year), [today, year]);

    useEffect(() => {
        handleDateSelect(currentDate);
    }, [selectedCalendarId]);

    const handleDateSelect = (date) => {
        setSelectDate(date);

        const dateKey = date.format('YYYY-MM-DD');
        const avail = datesAvailability[dateKey] || { times: [], prices: [], fastPrices: [] };

        setAvailability(avail);
        setSelectedTimes([]);
    };

    useEffect(() => {
        setDatesAvailability({});
        handleDateSelect(currentDate);
    }, [selectedAmbulance]);

    useEffect(() => {
        setDatesAvailability({});
        setSelectedTimes([]);
        setAvailability({ times: [], prices: [], fastPrices: [] });
    }, [selectedDoctor]);

    useEffect(() => {
        if (!selectedDoctor) {
            setDatesAvailability({});
            setAvailability({ times: [], prices: [], fastPrices: [] });
            return;
        }

        let isCancelled = false;

        const fetchAvailabilityForSelectedDoctor = async () => {
            try {
                const response = await fetch(
                    `${baseURL}/appointments/freeByCalendar/${selectedCalendarId}`
                );
                if (!response.ok) {
                    throw new Error('Failed to fetch availability data');
                }

                const availabilityData = await response.json();
                const availabilityMap = {};
                availabilityData[0].appointments.forEach(appointment => {
                    availabilityMap[appointment.date] = {
                        times: appointment.times,
                        prices: appointment.prices || [],
                        fastPrices: appointment.fastPrices || []
                    };
                });

                if (!isCancelled) {
                    setDatesAvailability(availabilityMap);

                    const todayFormatted = currentDate.format('YYYY-MM-DD');
                    if (!availabilityMap[todayFormatted]) {
                        availabilityMap[todayFormatted] = { times: [], prices: [], fastPrices: [] };
                    }

                    setAvailability(availabilityMap[todayFormatted]);
                }
            } catch (error) {
                console.error("Error fetching availability data:", error);
            }
        };

        if (selectedCalendarId) {
            fetchAvailabilityForSelectedDoctor();
        }

        return () => {
            isCancelled = true;
        };
    }, [selectedCalendarId, selectedDoctor]);

    const fetchPricesForSelectedDoctor = async () => {
        if (selectedDoctor) {
            try {
                const responsePrices = await fetch(
                    `${baseURL}/prices/findByDoctor/${selectedDoctor.doctorId}`
                );
                if (!responsePrices.ok) {
                    throw new Error('Failed to fetch prices data');
                }
                const pricesData = await responsePrices.json();
                setFetchedPrices(pricesData);

                const responseCards = await fetch(
                    `${baseURL}/cards/${selectedDoctor.doctorId}`
                );
                if (!responseCards.ok) {
                    throw new Error('Failed to fetch card prices data');
                }
                const cardsData = await responseCards.json();
                const cardPrices = {};
                cardsData.forEach(card => {
                    cardPrices[card.cardGroup] = {
                        basic: card.basic,
                        plus: card.plus,
                        max: card.max,
                        name: card.name,
                        cardId: card.cardId
                    };
                });
                setFetchedCardPrices(cardPrices);

                // Set allowedCards based on fetchedCardPrices
                setAllowedCards({
                    rocna_basic: cardPrices.ROCNA?.basic != null,
                    rocna_plus: cardPrices.ROCNA?.plus != null,
                    rocna_max: cardPrices.ROCNA?.max != null,
                    multi: cardPrices.MULTI != null,
                });

                setLocalPrices({});
            } catch (error) {
                console.error("Error fetching prices data:", error);
            }
        }
    };

    useEffect(() => {
        fetchPricesForSelectedDoctor();
    }, [selectedDoctor]);

    const handleTimeSelect = (timeSlot) => {
        setSelectedTimes((prevSelectedTimes) => {
            if (prevSelectedTimes.includes(timeSlot)) {
                return prevSelectedTimes.filter((time) => time !== timeSlot);
            } else {
                return [...prevSelectedTimes, timeSlot];
            }
        });
    };

    const getBackgroundColorClass = (date) => {
        const availabilityForDate = datesAvailability[date.format('YYYY-MM-DD')] || { times: [] };
        if (availabilityForDate.times.length > 0) {
            return "bg-blue-400";
        }
        return "bg-slate-400";
    };

    const getTimeSlotClass = (timeSlot) => {
        if (selectedTimes.includes(timeSlot)) {
            return "bg-gray-600 text-white";
        }
        return "bg-white hover:bg-slate-400 hover:text-white";
    };

    const handlePriceChange = (e) => {
        const { name, value } = e.target;
        setLocalPrices(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCenaTerminuChange = (e) => {
        setCenaTerminu(e.target.value);
    };

    const handleCenaTerminuSave = async () => {
        if (!selectedTimes.length || !cenaTerminu) {
            console.error('Please select at least one time and enter a price.');
            toast.error('Chyba: Vyberte aspoň jeden termín a nastavte cenu');
            return;
        }

        const authToken = localStorage.getItem('authToken');

        if (!authToken) {
            console.error('No auth token found. Please login.');
            alert('Chyba: Prosím prihláste sa');
            return;
        }

        try {
            const appointmentIds = selectedTimes.map(timeSlot => timeSlot.appointmentId);

            const response = await fetch(
                `${baseURL}/appointments/updateSpecialPrices`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    },
                    body: JSON.stringify({
                        appointmentIds,
                        specialPrice: parseFloat(cenaTerminu)
                    })
                }
            );

            if (response.ok) {
                toast.success('Špeciálna cena úspešne nastavená');
            } else {
                console.error('Failed to update special prices:', response.status, response.statusText);
                toast.error('Nepodarilo sa nastaviť špeciálnu cenu');
            }
        } catch (error) {
            console.error('Error updating special price:', error);
            toast.error('Nepodarilo sa nastaviť špeciálnu cenu');
        }
    };

    const Save = async () => {
        if (!selectedDoctor) {
            console.error('No doctor selected');
            toast.error('Chyba: Prosím vyberte lekára');
            return;
        }
        const doctorId = selectedDoctor.doctorId;

        const generalData = {
            vv: [
                parseFloat(localPrices.registracny_poplatok || fetchedPrices?.vv?.[0] || 0),
                parseFloat(localPrices.registracny_poplatok_10day || fetchedPrices?.vv?.[1] || 0),
                parseFloat(localPrices.registracny_poplatok_5day || fetchedPrices?.vv?.[2] || 0),
            ],
            basic: allowedCards.rocna_basic
                ? [
                    0,
                    parseFloat(localPrices.rocna_basic_10day || fetchedPrices?.basic?.[1] || 0),
                    parseFloat(localPrices.rocna_basic_5day || fetchedPrices?.basic?.[2] || 0),
                ]
                : [null, null, null],
            plus: allowedCards.rocna_plus
                ? [
                    0,
                    parseFloat(localPrices.rocna_plus_10day || fetchedPrices?.plus?.[1] || 0),
                    parseFloat(localPrices.rocna_plus_5day || fetchedPrices?.plus?.[2] || 0),
                ]
                : [null, null, null],
            max: allowedCards.rocna_max
                ? [
                    0,
                    parseFloat(localPrices.rocna_max_10day || fetchedPrices?.max?.[1] || 0),
                    parseFloat(localPrices.rocna_max_5day || fetchedPrices?.max?.[2] || 0),
                ]
                : [null, null, null],
            multi: allowedCards.multi
                ? [
                    0,
                    parseFloat(localPrices.multi_10day || fetchedPrices?.multi?.[1] || 0),
                    parseFloat(localPrices.multi_5day || fetchedPrices?.multi?.[2] || 0),
                ]
                : [null, null, null],
        };

        try {
            const authToken = localStorage.getItem('authToken');

            if (!authToken) {
                console.error('No auth token found. Please login.');
                alert('Chyba: Prosím prihláste sa');
                return;
            }

            const responseGeneral = await fetch(
                `${baseURL}/prices/${doctorId}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    },
                    body: JSON.stringify(generalData)
                }
            );

            if (!responseGeneral.ok) {
                console.error('Failed to update general prices:', responseGeneral.status, responseGeneral.statusText);
                toast.error('Nepodarilo sa nastaviť ceny');
                return;
            }

            // Update card prices for Ročná karta
            if (fetchedCardPrices && fetchedCardPrices.ROCNA) {
                const rocnaCard = fetchedCardPrices.ROCNA;
                const updatedRocnaPrices = {
                    basic: allowedCards.rocna_basic
                        ? parseFloat(localPrices.rocna_basic_card_price || rocnaCard.basic) || 0
                        : null,
                    plus: allowedCards.rocna_plus
                        ? parseFloat(localPrices.rocna_plus_card_price || rocnaCard.plus) || 0
                        : null,
                    max: allowedCards.rocna_max
                        ? parseFloat(localPrices.rocna_max_card_price || rocnaCard.max) || 0
                        : null,
                };

                const payload = {
                    basic: updatedRocnaPrices.basic,
                    plus: updatedRocnaPrices.plus,
                    max: updatedRocnaPrices.max
                };

                try {
                    const responseRocna = await fetch(
                        `${baseURL}/cards/${rocnaCard.cardId}`,
                        {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${authToken}`
                            },
                            body: JSON.stringify(payload)
                        }
                    );

                    if (!responseRocna.ok) {
                        console.error('Failed to update Ročná karta prices:', responseRocna.status, responseRocna.statusText);
                        toast.error('Nepodarilo sa zmeniť cenu pre Ročnú kartu');
                        return;
                    }
                } catch (error) {
                    console.error('Error updating Ročná karta prices:', error);
                    toast.error('Nepodarilo sa zmeniť cenu pre Ročnú kartu');
                    return;
                }
            } else {
                console.error('Ročná karta data not found.');
                toast.error('Nenájdené data pre ročnú kartu.');
                return;
            }

            // Update card prices for Multi karta if applicable
            if (fetchedCardPrices && fetchedCardPrices.MULTI && allowedCards.multi) {
                const multiCard = fetchedCardPrices.MULTI;
                const updatedMultiPrices = {
                    basic: parseFloat(localPrices.multi_basic_card_price || multiCard.basic) || 0,
                    plus: parseFloat(localPrices.multi_plus_card_price || multiCard.plus) || 0,
                    max: parseFloat(localPrices.multi_max_card_price || multiCard.max) || 0,
                };

                const payload = {
                    basic: updatedMultiPrices.basic,
                    plus: updatedMultiPrices.plus,
                    max: updatedMultiPrices.max
                };

                try {
                    const responseMulti = await fetch(
                        `${baseURL}/cards/${multiCard.cardId}`,
                        {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${authToken}`
                            },
                            body: JSON.stringify(payload)
                        }
                    );

                    if (!responseMulti.ok) {
                        console.error('Failed to update Multi karta prices:', responseMulti.status, responseMulti.statusText);
                        toast.error('Nepodarilo sa zmeniť cenu pre Multi kartu');
                        return;
                    }
                } catch (error) {
                    console.error('Error updating Multi karta prices:', error);
                    toast.error('Nepodarilo sa zmeniť cenu pre Multi kartu');
                    return;
                }
            }

            toast.success('Ceny nastavené úspešne');
            setLocalPrices({});

            fetchPricesForSelectedDoctor();
        } catch (error) {
            console.error('Error updating prices:', error);
            toast.error('Nastala chyba pri nastavení cien');
        }
    };

    const Back = () => {
        navigate('/login');
    };

    const handleAllowedCardsChange = (e) => {
        const { name, checked } = e.target;
        setAllowedCards(prev => ({
            ...prev,
            [name]: checked,
        }));
    };

    return (
        <div className="bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: "url('/pictures/background.jpg')" }}>
            <div className="p-4 sm:p-8 bg-slate-100 relative w-full h-full">
            <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="flex flex-col sm:flex-row sm:gap-4 justify-between items-start rounded-lg p-4 sm:p-8">
                    {/* Left - Calendar */}
                    <div className="w-full sm:w-auto sm:flex-shrink-0">
                        <div className="flex justify-between items-center mb-4 text-black">
                            <div className="flex items-center">
                                <select
                                    value={year}
                                    onChange={(e) => {
                                        setYear(parseInt(e.target.value));
                                        setToday(today.year(parseInt(e.target.value)));
                                    }}
                                    className="select-none font-semibold cursor-pointer bg-slate-100 border border-black text-black rounded-lg p-1"
                                >
                                    {years.map(y => (
                                        <option key={y} value={y}>
                                            {y}
                                        </option>
                                    ))}
                                </select>
                                <h1 className="select-none font-semibold inline ml-2">
                                    {months[today.month()]}
                                </h1>
                            </div>
                            <div className="flex gap-2 items-center font-semibold">
                                <GrFormPrevious
                                    className="w-5 h-5 cursor-pointer hover:scale-105 transition-all"
                                    onClick={() => {
                                        if (today.month() === 0) {
                                            setToday(today.subtract(1, 'year').month(11));
                                            setYear(year - 1);
                                        } else {
                                            setToday(today.subtract(1, 'month'));
                                        }
                                    }}
                                />
                                <h1
                                    className="cursor-pointer hover:scale-105 transition-all"
                                    onClick={() => {
                                        setToday(currentDate);
                                        setYear(currentDate.year());
                                    }}
                                >
                                    Dnes
                                </h1>
                                <GrFormNext
                                    className="w-5 h-5 cursor-pointer hover:scale-105 transition-all"
                                    onClick={() => {
                                        if (today.month() === 11) {
                                            setToday(today.add(1, 'year').month(0));
                                            setYear(year + 1);
                                        } else {
                                            setToday(today.add(1, 'month'));
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-5 border-b border-black">
                            {days.map((day, index) => (
                                <h1
                                    key={index}
                                    className="text-sm text-center h-10 w-14 grid place-content-center text-black select-none"
                                >
                                    {day}
                                </h1>
                            ))}
                        </div>
                        <div className="grid grid-cols-5 grid-rows-6">
                            {dates.slice(0, 25).map(({ date, currentMonth }, index) => {
                                const isPast = date.isBefore(currentDate, 'day');
                                const isSelected = selectDate.isSame(date, 'day');
                                const bgColorClass = !isPast
                                    ? isSelected
                                        ? "bg-green-600 text-white"
                                        : getBackgroundColorClass(date)
                                    : "text-black cursor-not-allowed bg-slate-400";

                                const hoverClass = !isPast && !isSelected ? "hover:bg-green-400" : "";

                                return (
                                    <div
                                        key={index}
                                        className="p-1 text-center h-14 grid place-content-center text-sm border-t border-black"
                                    >
                                        <h1
                                            className={cn(
                                                currentMonth ? "" : "text-white",
                                                bgColorClass,
                                                "h-12 w-12 grid place-content-center transition-all cursor-pointer select-none border border-black",
                                                isPast && "cursor-not-allowed",
                                                !isSelected && hoverClass
                                            )}
                                            onClick={() => {
                                                if (!isPast) {
                                                    handleDateSelect(date);
                                                }
                                            }}
                                        >
                                            {date.date()}
                                        </h1>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {/* Divider */}
                    <div className="w-px bg-black mx-1 self-stretch"></div>

                    {/* Middle - Timeslots */}
                    <div className="flex flex-col h-auto max-h-96 overflow-y-auto text-black w-full sm:w-1/2">
                        <div className="mb-4">
                            <h1 className="font-bold text-sm sm:text-base md:text-lg">
                                Všetky termíny na deň: {formatDate(selectDate)}
                            </h1>
                            <div className="mt-4">
                                {availability.times.length > 0 ? (
                                    <div className="grid grid-cols-3 gap-2">
                                        {availability.times
                                            .sort((a, b) => {
                                                const timeA = a.time.split(":").map(Number);
                                                const timeB = b.time.split(":").map(Number);
                                                return timeA[0] - timeB[0] || timeA[1] - timeB[1];
                                            })
                                            .map((timeSlot, index) => (
                                                <div
                                                    key={index}
                                                    className={cn(
                                                        "cursor-pointer p-1 rounded border border-black flex items-center justify-center transition-all h-11",
                                                        getTimeSlotClass(timeSlot)
                                                    )}
                                                    onClick={() => handleTimeSelect(timeSlot)}
                                                >
                                                    {timeSlot.time.slice(0, 5)}
                                                </div>
                                            ))}
                                    </div>
                                ) : (
                                    <p className="text-gray-600">Žiadne termíny na tento deň.</p>
                                )}
                            </div>

                            {/* Horizontal Divider */}
                            <div className="w-full h-px bg-black my-4"></div>

                            {/* Middle - Special price */}
                            <div className="flex flex-col items-center space-y-4">
                                <div className="flex items-center justify-center w-full">
                                    <label className="font-semibold text-sm sm:text-base md:text-lg">
                                        Nastav cenu termínu:
                                    </label>
                                    <input
                                        data-testid="cena-terminu-input"
                                        type="text"
                                        name="cenaTerminu"
                                        value={cenaTerminu}
                                        onChange={handleCenaTerminuChange}
                                        className="ml-2 p-2 border border-gray-300 rounded w-1/3 text-sm sm:text-base"
                                    />
                                </div>
                                <button
                                    className="py-2 px-4 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition-colors w-1/2"
                                    onClick={handleCenaTerminuSave}
                                >
                                    Ulož cenu
                                </button>
                            </div>

                            {/* Horizontal Divider */}
                            <div className="w-full h-px bg-black my-4"></div>

                            {/* Middle - Allowed cards */}
                            <div className="flex flex-col space-y-2 mt-4">
                                <h1 className="font-bold text-sm sm:text-base md:text-lg">Povolené typy karty:</h1>
                                <div className="grid grid-cols-4 gap-1">
                                    <div></div>
                                    <div className="text-center font-semibold">Basic</div>
                                    <div className="text-center font-semibold">Plus</div>
                                    <div className="text-center font-semibold">Max</div>

                                    <div className="text-center font-semibold">Ročná:</div>
                                    <input
                                        type="checkbox"
                                        name="rocna_basic"
                                        checked={allowedCards.rocna_basic || false}
                                        onChange={handleAllowedCardsChange}
                                    />
                                    <input
                                        type="checkbox"
                                        name="rocna_plus"
                                        checked={allowedCards.rocna_plus || false}
                                        onChange={handleAllowedCardsChange}
                                    />
                                    <input
                                        type="checkbox"
                                        name="rocna_max"
                                        checked={allowedCards.rocna_max || false}
                                        onChange={handleAllowedCardsChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Divider */}
                    <div className="w-px bg-black mx-1 self-stretch"></div>

                    {/* Right - Price */}
                    <div className="flex flex-col h-auto max-h-96 overflow-y-auto text-black w-full sm:w-2/5">
                        <div>
                            <div className="flex items-center justify-center"></div>
                            <div>
                                <div className="flex flex-col space-y-3">
                                    <h1 className="font-bold text-sm sm:text-base md:text-lg">
                                        Registračný poplatok
                                    </h1>
                                    <div className="flex flex-row space-x-2 mt-2">
                                        <div className="flex flex-col">
                                            <label className="text-xs text-center">Cena služby</label>
                                            <input
                                                data-testid="registracny-poplatok-input"
                                                type="text"
                                                name="registracny_poplatok"
                                                value={localPrices.registracny_poplatok || ''}
                                                onChange={handlePriceChange}
                                                className="p-2 border border-gray-300 rounded w-full text-sm sm:text-base"
                                                placeholder={
                                                    fetchedPrices && fetchedPrices.vv
                                                        ? fetchedPrices.vv[0]
                                                        : ''
                                                }
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label className="text-xs text-center">5-dni</label>
                                            <input
                                                type="text"
                                                name="registracny_poplatok_5day"
                                                value={localPrices.registracny_poplatok_5day || ''}
                                                onChange={handlePriceChange}
                                                className="p-2 border border-gray-300 rounded w-full text-sm sm:text-base"
                                                placeholder={
                                                    fetchedPrices && fetchedPrices.vv
                                                        ? fetchedPrices.vv[2]
                                                        : ''
                                                }
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label className="text-xs text-center">10-dni</label>
                                            <input
                                                type="text"
                                                name="registracny_poplatok_10day"
                                                value={localPrices.registracny_poplatok_10day || ''}
                                                onChange={handlePriceChange}
                                                className="p-2 border border-gray-300 rounded w-full text-sm sm:text-base"
                                                placeholder={
                                                    fetchedPrices && fetchedPrices.vv
                                                        ? fetchedPrices.vv[1]
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Ročná karta */}
                                <div className="flex flex-col space-y-3 mt-4">
                                    <h1 className="font-bold text-sm sm:text-base md:text-lg">Ročná karta</h1>
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="flex flex-col">
                                            <label className="text-sm text-center font-semibold">Basic</label>
                                        </div>
                                        <div className="flex flex-col">
                                            <label className="text-sm text-center font-semibold">Plus</label>
                                        </div>
                                        <div className="flex flex-col">
                                            <label className="text-sm text-center font-semibold">Max</label>
                                        </div>

                                        <div className="flex flex-col">
                                            <label className="text-xs text-center">Cena karty</label>
                                            <input
                                                type="text"
                                                name="rocna_basic_card_price"
                                                value={localPrices.rocna_basic_card_price || ''}
                                                onChange={handlePriceChange}
                                                className={`p-2 border border-gray-300 rounded w-full text-sm sm:text-base ${
                                                    !allowedCards.rocna_basic ? 'bg-gray-200 cursor-not-allowed' : ''
                                                }`}
                                                placeholder={
                                                    fetchedCardPrices && fetchedCardPrices.ROCNA
                                                        ? fetchedCardPrices.ROCNA.basic
                                                        : ''
                                                }
                                                disabled={!allowedCards.rocna_basic}
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label className="text-xs text-center">Cena karty</label>
                                            <input
                                                type="text"
                                                name="rocna_plus_card_price"
                                                value={localPrices.rocna_plus_card_price || ''}
                                                onChange={handlePriceChange}
                                                className={`p-2 border border-gray-300 rounded w-full text-sm sm:text-base ${
                                                    !allowedCards.rocna_plus ? 'bg-gray-200 cursor-not-allowed' : ''
                                                }`}
                                                placeholder={
                                                    fetchedCardPrices && fetchedCardPrices.ROCNA
                                                        ? fetchedCardPrices.ROCNA.plus
                                                        : ''
                                                }
                                                disabled={!allowedCards.rocna_plus}
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label className="text-xs text-center">Cena karty</label>
                                            <input
                                                type="text"
                                                name="rocna_max_card_price"
                                                value={localPrices.rocna_max_card_price || ''}
                                                onChange={handlePriceChange}
                                                className={`p-2 border border-gray-300 rounded w-full text-sm sm:text-base ${
                                                    !allowedCards.rocna_max ? 'bg-gray-200 cursor-not-allowed' : ''
                                                }`}
                                                placeholder={
                                                    fetchedCardPrices && fetchedCardPrices.ROCNA
                                                        ? fetchedCardPrices.ROCNA.max
                                                        : ''
                                                }
                                                disabled={!allowedCards.rocna_max}
                                            />
                                        </div>

                                        {/* Row 2: 5 dni */}
                                        <div className="flex flex-col">
                                            <label className="text-xs text-center">5 dni</label>
                                            <input
                                                type="text"
                                                name="rocna_basic_5day"
                                                value={localPrices.rocna_basic_5day || ''}
                                                onChange={handlePriceChange}
                                                className={`p-2 border border-gray-300 rounded w-full text-sm sm:text-base ${
                                                    !allowedCards.rocna_basic ? 'bg-gray-200 cursor-not-allowed' : ''
                                                }`}
                                                placeholder={
                                                    fetchedPrices && fetchedPrices.basic
                                                        ? fetchedPrices.basic[2]
                                                        : ''
                                                }
                                                disabled={!allowedCards.rocna_basic}
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label className="text-xs text-center">5 dni</label>
                                            <input
                                                type="text"
                                                name="rocna_plus_5day"
                                                value={localPrices.rocna_plus_5day || ''}
                                                onChange={handlePriceChange}
                                                className={`p-2 border border-gray-300 rounded w-full text-sm sm:text-base ${
                                                    !allowedCards.rocna_plus ? 'bg-gray-200 cursor-not-allowed' : ''
                                                }`}
                                                placeholder={
                                                    fetchedPrices && fetchedPrices.plus
                                                        ? fetchedPrices.plus[2]
                                                        : ''
                                                }
                                                disabled={!allowedCards.rocna_plus}
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label className="text-xs text-center">5 dni</label>
                                            <input
                                                type="text"
                                                name="rocna_max_5day"
                                                value={localPrices.rocna_max_5day || ''}
                                                onChange={handlePriceChange}
                                                className={`p-2 border border-gray-300 rounded w-full text-sm sm:text-base ${
                                                    !allowedCards.rocna_max ? 'bg-gray-200 cursor-not-allowed' : ''
                                                }`}
                                                placeholder={
                                                    fetchedPrices && fetchedPrices.max
                                                        ? fetchedPrices.max[2]
                                                        : ''
                                                }
                                                disabled={!allowedCards.rocna_max}
                                            />
                                        </div>

                                        {/* Row 3: 10 dni */}
                                        <div className="flex flex-col">
                                            <label className="text-xs text-center">10 dni</label>
                                            <input
                                                type="text"
                                                name="rocna_basic_10day"
                                                value={localPrices.rocna_basic_10day || ''}
                                                onChange={handlePriceChange}
                                                className={`p-2 border border-gray-300 rounded w-full text-sm sm:text-base ${
                                                    !allowedCards.rocna_basic ? 'bg-gray-200 cursor-not-allowed' : ''
                                                }`}
                                                placeholder={
                                                    fetchedPrices && fetchedPrices.basic
                                                        ? fetchedPrices.basic[1]
                                                        : ''
                                                }
                                                disabled={!allowedCards.rocna_basic}
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label className="text-xs text-center">10 dni</label>
                                            <input
                                                type="text"
                                                name="rocna_plus_10day"
                                                value={localPrices.rocna_plus_10day || ''}
                                                onChange={handlePriceChange}
                                                className={`p-2 border border-gray-300 rounded w-full text-sm sm:text-base ${
                                                    !allowedCards.rocna_plus ? 'bg-gray-200 cursor-not-allowed' : ''
                                                }`}
                                                placeholder={
                                                    fetchedPrices && fetchedPrices.plus
                                                        ? fetchedPrices.plus[1]
                                                        : ''
                                                }
                                                disabled={!allowedCards.rocna_plus}
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label className="text-xs text-center">10 dni</label>
                                            <input
                                                type="text"
                                                name="rocna_max_10day"
                                                value={localPrices.rocna_max_10day || ''}
                                                onChange={handlePriceChange}
                                                className={`p-2 border border-gray-300 rounded w-full text-sm sm:text-base ${
                                                    !allowedCards.rocna_max ? 'bg-gray-200 cursor-not-allowed' : ''
                                                }`}
                                                placeholder={
                                                    fetchedPrices && fetchedPrices.max
                                                        ? fetchedPrices.max[1]
                                                        : ''
                                                }
                                                disabled={!allowedCards.rocna_max}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 flex justify-center"></div>
                        </div>
                    </div>
                </div>

                <div className="relative mt-4 sm:absolute sm:bottom-4 sm:left-4 flex justify-center sm:justify-start">
                    <button
                        className="bg-blue-600 mr-5 text-white p-4 rounded-full"
                        onClick={Back}
                    >
                        <GrFormPrevious className="w-6 h-6" />
                    </button>
                </div>
                <div className="relative mt-4 sm:absolute sm:bottom-4 sm:right-4 flex justify-center sm:justify-end">
                    <button
                        className="py-3 px-8 rounded transition-all bg-green-600 text-white hover:shadow-md"
                        onClick={Save}
                    >
                        Ulož
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AdminPrices;
